.closebtn{
    position: absolute;
    right: 11px;
    top: 7px;
    cursor: pointer;
}
.passwordTitle {
    display: flex;
    justify-content: center;
    color: #122D5B !important;
    font-weight: bold !important;
    font-family: var(--font-family);
    margin-bottom: 0;
    font-size: 32px;
    margin-top: 23px;
}
.submitBtn{
    background-color: var(--background-color) !important;
    color: #fff !important;
    border-radius: 33px !important;
    padding: 14px 50px !important;
    font-weight: bold !important;
    cursor: pointer;
    border: none !important;
    font-size: 16px !important;
}
.errorMessage{
    font-size: 12px;
    color: red;
    margin-top: -3px;
    margin-left: 19px;
    font-family: var(--poppins-regular);
}
.inputError{
    border: 0.5px solid red !important;
    border-radius: 33px;
    
    &:hover{
        border-color: red !important;
    }
}
.backSign{
    color: #000000;
    font-size: 16px;
    margin-top: 16px !important;
    margin-bottom: 18px !important;
    display: flex;
    justify-content: center;
    font-family: var(--poppins-regular);
    margin: 0;

    span{
    color: #122d5b;
    font-size: 16px;
    margin-left: 5px;
    font-family: var(--poppins-bold);
    cursor: pointer;
    }
}