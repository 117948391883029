.accordionWrapper {
  width: 100%;
  word-wrap: break-word;
  .accordion {
    width: 100%;
    height: 78px;
    background-color: #182d5b;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    .organIcon {
      position: relative;
      right: 16px;
      height: 40px;
      width: 40px;
      user-select: none;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .accordionHeadings {
      color: #122d5b;
      font-size: 20px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      user-select: none;
      width: 80%;
      .organName {
        color: white;
        font-family: var(--poppins-medium);
        font-size: 20px;
        font-weight: 400;
        width: 40%;
        text-align: right;
      }
      .verticalLine {
        background-color: #ec5c52;
        width: 7px;
        height: 42px;
        border-radius: 4px;
      }
      .reportLevel {
        text-align: left;
        font-family: var(--poppins-medium);
        font-size: 18px;
        font-weight: 400;
        width: 40%;
      }
    }
    .expandedIcon {
      background-color: unset;
      outline: none;
      border: none;
      position: relative;
      left: 18px;
      cursor: pointer;
      &.expanded {
        transform: rotate(0deg);
      }
      &.closed {
        transform: rotate(180deg);
      }
    }
    &.roundBorder {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }
    &.angleBorder {
      border-radius: 15px;
    }
  }
  .AccordionDetails {
    overflow: hidden;
    width: 99%;
    position: relative;
    top: -3px;
    margin: auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
    border-radius: 7px;
    .organGraphWrapper {
      user-select: none;
      position: relative;
      .yAxisLabels {
        position: absolute;
        width: 100%;
        height: 82%;
        display: flex;
        z-index: 10;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 6px;
        .DyFunctional,
        .Problematic,
        .Stressed,
        .Good {
          width: 100%;
          padding-left: 18px;
          font-size: 14px;
          font-family: var(--poppins-medium);
          color: #ae8181;
          line-height: 19px;
          user-select: none;
          display: flex;
          align-items: center;
        }
        .DyFunctional {
          height: 25%;
        }
        .Problematic {
          height: 17%;
        }
        .Stressed {
          height: 17%;
        }
        .Good {
          height: 41%;
        }
      }
      .paginationButtons {
        width: 100%;
        height: 100%;
        position: absolute;
        color: #405973;
        font-size: 10px;
        line-height: 14px;
        font-family: var(--poppins-medium);
        div {
          width: 80%;
          height: 100%;
          margin-left: auto;
          margin-right: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: end;
          button {
            cursor: pointer;
            margin-bottom: 0.8rem;
            background-color: unset;
            outline: unset;
            border: unset;
            padding: unset;
            user-select: none;
          }
        }
      }
      canvas {
        width: 100% !important;
      }
    }
    .organInformation {
      background-color: #f0f8ff;
      border-radius: 7px;
      width: 96%;
      margin: 10px auto;
      padding: 15px;
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0;
        font-size: 18px;
        user-select: none;
        font-family: var(--poppins-semibold);
      }
      p {
        margin: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        margin: 0 auto;
        font-family: var(--poppins-regular);
        text-align: justify;
        user-select: none;
      }
      button {
        border: none;
        background-color: unset;
        cursor: pointer;
        font-weight: normal;
        text-transform: capitalize;
        width: fit-content;
        margin: 0 auto;
        font-family: var(--poppins-semibold);
        user-select: none;
      }
    }
    &.openAccordion {
      max-height: auto;
    }
    &.closeAccordion {
      max-height: 0px;
    }
  }
}

@media (max-width: 2048px) {
  .accordionWrapper {
    .accordion {
      height: 70px;
      .accordionHeadings {
        .organName {
          font-size: 17px;
        }
        .reportLevel {
          font-size: 17px;
        }
      }
    }
  }
}
@media (max-width: 1919px) {
  .accordionWrapper {
    .accordion {
      height: 57px;
      .organIcon {
        height: 37px;
        width: 37px;
      }
      .accordionHeadings {
        .organName {
          font-size: 16px;
        }
        .verticalLine {
          width: 5px;
          height: 33px;
        }
        .reportLevel {
          font-size: 15px;
        }
      }
    }
    .AccordionDetails {
      .organInformation {
        h4 {
          font-size: 16px;
          margin-bottom: 5px;
          font-family: var(--poppins-semibold);
        }
        p {
          font-size: 12px;
          font-family: var(--poppins-regular);
        }
      }
    }
  }
}

@media (max-width: 1707px) {
  .accordionWrapper {
    .accordion {
      height: 50px;
      .organIcon {
        right: 12px;
      }
      .accordionHeadings {
        .organName {
          font-size: 14px;
        }
        .verticalLine {
          width: 4px;
          height: 31px;
        }
        .reportLevel {
          font-size: 13px;
        }
      }
      .expandedIcon {
        left: 15px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .AccordionDetails {
      .organGraphWrapper {
        .yAxisLabels {
          .DyFunctional,
          .Problematic,
          .Stressed,
          .Good {
            font-size: 12px;
          }
        }
      }
      .organInformation {
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 11px;
        }
        button {
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 1536px) {
  .accordionWrapper {
    .accordion {
      height: 50px;
      .organIcon {
        right: 12px;
      }
      .accordionHeadings {
        .organName {
          font-size: 14px;
        }
        .verticalLine {
          width: 4px;
          height: 31px;
        }
        .reportLevel {
          font-size: 13px;
        }
      }
      .expandedIcon {
        left: 15px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .AccordionDetails {
      .organGraphWrapper {
        .yAxisLabels {
          .DyFunctional,
          .Problematic,
          .Stressed,
          .Good {
            font-size: 12px;
          }
        }
      }
      .organInformation {
        padding: 10px;
        h4 {
          font-size: 13px;
          margin-bottom: 0;
        }
        p {
          font-size: 10px;
        }
        button {
          font-size: 10px;
        }
      }
    }
  }
}
@media (max-width: 1395px) {
  .accordionWrapper {
    .AccordionDetails {
      .organGraphWrapper {
        .yAxisLabels {
          .DyFunctional,
          .Problematic,
          .Stressed,
          .Good {
            font-size: 12px;
          }
        }
      }
      .organInformation {
        padding: 10px;
        h4 {
          font-size: 13px;
          margin-bottom: 0;
        }
        p {
          font-size: 10px;
        }
        button {
          font-size: 10px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .accordionWrapper {
    .accordion {
      height: 40px;
      .organIcon {
        right: 12px;
        height: 32px;
        width: 32px;
      }
      .accordionHeadings {
        .organName {
          font-size: 14px;
        }
        .verticalLine {
          width: 4px;
          height: 31px;
        }
        .reportLevel {
          font-size: 13px;
        }
      }
      .expandedIcon {
        left: 15px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .AccordionDetails {
      display: flex;
      justify-content: space-around;
      .organGraphWrapper {
        padding: 0.5rem;
        width: 49%;
        .yAxisLabels {
          .DyFunctional,
          .Problematic,
          .Stressed,
          .Good {
            font-size: 10px;
          }
        }
      }
      .organInformation {
        width: 49%;
        padding: 10px;
        height: min-content;
        h4 {
          font-size: 13px;
          margin-bottom: 0;
        }
        p {
          font-size: 10px;
        }
        button {
          font-size: 10px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .AccordionDetails {
    display: block !important;
  }
  .organGraphWrapper {
    width: 100% !important;
    height: auto !important;
  }
  .organInformation {
    width: auto !important;
    margin: 9px !important;
  }
}
