.closeIcon {
  display: flex;
  cursor: pointer;
  justify-content: end;
  align-items: center;
}
.notificationMessage {
  color: #122d5b;
  font-family: var(--poppins-medium);
  text-align: center;
  margin: 12px;
  font-size: 20px;
  word-wrap: break-word;
}
.paymentMethodLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > label {
    color: #122d5b;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
  }
  & .paymentButton {
    background-color: #122d5b;
    padding: 16px 0;
    border-radius: 33px;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    margin-block: 20px 30px;
  }
}
.user_plan {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lable_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 15px;
  padding: 28px 36px 28px 23px;
  margin-block: 1rem;

  .radioWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  label {
    gap: 27px;
  }

  p {
    color: #ff9900;
    font-size: 17px;
    margin: 0;
  }

  span {
    color: #122d5b;
    font-size: 1rem;
    font-family: var(--poppins-regular);
    padding: 0;
  }

  &.selectedPlans {
    pointer-events: none;
    user-select: none;
    box-shadow: 10px 20px 40px #00000014;
    border: 3px solid #122d5b;
  }
}
.font_normal {
  font-family: var(--poppins-medium);
}
.inputField {
  width: 100%;
  padding-block: 12px;
  padding-left: 25px;
  background-color: #f4f4f4;
  border-radius: 15px;
  color: #122d5b;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid lightgray;

  &:focus-visible {
    outline: none;
  }
}