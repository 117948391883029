.otpBox {
  // display: grid;
  // justify-content: center;

  p {
    font-family: var(--font-family);
    color: #8a8a8a;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
  }

  h3 {
    font-family: var(--font-family);
    color: var(--background-color);
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .verification {
    font-family: var(--font-family);
    color: #8a8a8a;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .mail {
    font-family: var(--font-family);
    color: var(--background-color);
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    margin-top: 5px;
  }
}
.otpInputNumber {
  width: 50px;
  height: 50px;
  font-size: 25px;
  color: var(--background-color) !important;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  font-weight: bold !important;
  font-family: var(--poppins-bold);
  text-align: center;

  &:focus {
    border: var(--background-color) 1px solid !important;
    text-align: center;
  }

  &:focus-visible {
    outline: none;
  }
  input:focus-visible {
    outline: none;
  }

  input:focus {
    border: var(--background-color) 1px solid !important;
    text-align: center;
  }
}

.submitBtn {
  background-color: var(--background-color) !important;
  color: #fff !important;
  border-radius: 33px !important;
  padding-block: 20px !important;
  width: 100%;
  font-weight: bold !important;
  cursor: pointer;
  border: none !important;
  margin-top: 36px !important;
  margin-bottom: 0 !important;
  font-size: 18px !important;
  font-family: var(--poppins-bold) !important;
  line-height: 25px !important;
}
.otpErrorMessage{
  color: red;
  font-size: 13px;
  text-align: start;
  margin-bottom: 0;
  width: 100%;
}
.resendLink{
  color: var(--background-color);
  font-family: var(--poppins-bold);
  font-size: 15px;
  margin-top: 19px;
  border: none;
  cursor: pointer;
  float: right;
  display: flex;
  justify-content: end;
  width: 100%;
}