.mainContainer {
  padding-top: 47px;
  max-width: 1440px;
  padding-inline: 30px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainContainerClass{
  padding-top: 47px;
  max-width: 1440px;
  padding-inline: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LastBreadCrumb {
  font-size: 16px;
  font-family: var(--poppins-bold);
  font-weight: bold;
  color: #122d5b;
  padding-inline-start: 11px;
}
.BreadCrumbArrow {
  font-size: 16px;
  font-family: var(--poppins-regular);
  font-weight: 400;
  color: #000000;
}
.LinkBreadCrumb {
  font-size: 16px;
  font-family: var(--poppins-regular);
  font-weight: 400;
  color: #000000;
  padding-inline-end: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.FirstLinkBreadCrumb {
  padding-inline-start: 11px;
}
.LinkTo {
  text-decoration: none;
}
.buttonsContainer {
  display: flex;
  gap: 10px;
}
