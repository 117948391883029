.header_dropdown {
  &:hover {
    background: none !important;
  }
  &:active {
    background: none !important;
  }
  &:focus {
    background: none !important;
    outline: none !important;
  }
  &:focus-visible {
    outline: none !important;
    background: none !important;
  }
}
.bloodTestButton {
  border-radius: 33px;
  padding: 10px 22px 9px 23px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  justify-content: space-around;
  background-color: #122d5b;
  margin-left: 74px;
  font-family: "Poppins", sans-serif;
  border: none;
  margin-right: 36px;
  @media (min-width: 1024px) and (max-width: 1100px) {
    margin-left: 28px;
    font-size: 12px;
  }
  @media (min-width: 1101px) and (max-width: 1228px) {
    margin-left: 40px;
    font-size: 12px;
  }
  @media (min-width: 1229px) and (max-width: 1329px) {
    margin-right: 20px;
  }
  @media (min-width: 1330px) and (max-width: 1476px) {
    margin-right: 24px;
  }
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #122d5b;
  }
}
.menuItem {
  display: block !important;
  padding: 6px 16px !important;
  // margin-left: 26px !important;
}
