.mainBg {
  // background-color: #F3F2F4;

  .container {
    // padding: 10%;
    // padding-inline: 16.125rem;
    padding-top: 100px;
    // width: 1403px;

    h1 {
      color: var(--background-color);
      font-size: 34px;
      font-family: var(--poppins-semibold);
    }

    .profileCard {
      background-color: #ffffff;
      border-radius: 15px;
      padding: 10px 30px 10px 19px;
      box-shadow: none;

      p {
        color: var(--background-color);
        font-size: 18px;
        font-family: var(--poppins-semibold);
      }
    }

    .profileDesign {
      display: grid;
      grid-template-columns: auto auto auto;

      @media (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: auto auto;
      }

      gap: 15px;

      .showProfile {
        background-color: #f4f4f4;
        border: 1px solid #c1c1c1;
        color: var(--background-color);
        border-radius: 10px;
        width: 250px;
        cursor: pointer;

        &:hover {
          border: 1px solid var(--background-color);
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          width: 230px;
        }

        padding: 16px 26px 16px 26px;
        font-size: 16px;
        font-family: var(--poppins-semibold);
      }
    }

    .bloodTest_nodata {
      color: #000000;
      font-size: 16px;
      max-width: 70%;
      margin: auto;
      text-align: center;
      padding-block: 39px;
      font-family: var(--poppins-semibold) !important;
    }

    .addButton {
      width: 182px;
      background-color: var(--background-color);
      color: white;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border-radius: 21px;
      padding: 10px 39px 10px 41px;
      border: none;
      font-family: var(--font-family);
      cursor: pointer;
    }

    .reviewReport {
      background-color: white;
      border-radius: 15px;
      padding: 0px 20px 32px 20px;
      box-shadow: none;
      // margin-top: 17px;
      margin-top: 48px;
      position: relative;

      .report_title {
        font-size: 18px;
        color: var(--background-color);
        font-family: var(--poppins-semibold);
        /* padding-bottom: 11px; */
        padding-left: 19px;
        position: sticky;
        margin-bottom: 0;
        top: 0px;
        z-index: 9;
        background-color: white;
        margin-top: 0;
        line-height: 41px;
        padding-top: 10px;
        padding-bottom: 10px;
        // &:after {
        //   content: "";
        //   height: 30px;
        //   width: 100%;
        //   max-width: 419px;
        //   background: white;
        //   display: block;
        //   position: absolute;
        //   top: -28px;
        //   left: 18px;
        // }
      }

      .tr_profile_name {
        font-size: 16px;
        color: var(--background-color);
        font-family: var(--poppins-semibold);
        margin: 19px auto 11px 23px;
      }

      .report_update {
        background-color: #e68413;
        border-radius: 16px;
        color: white;
        font-family: var(--poppins-semibold);
        font-size: 12px;
        width: max-content;
        padding: 3px 20px;
        // margin-top: -14px;
        position: absolute;
        right: -6px;
        top: -15px;
        margin-right: 4px;
      }

      .tr_report_name {
        font-size: 16px;
        color: var(--background-color);
        font-family: var(--poppins-semibold);
        margin: 15px 9px 13px 10px;
      }

      // p {
      //     font-size: 18px;
      //     color: var(--background-color);
      //     font-family: var(--poppins-semibold);
      // }

      .trendReport {
        background-color: #ffffff;
        border: 1px solid #d6d6d6;
        // width: 100%;
        box-shadow: none;
        border-radius: 15px;
        height: 370px;
        position: relative;

        .tr_reportDetails {
          display: grid;
          gap: 22px;
          margin: auto 19px 22px 18px;
        }

        .tr_Text {
          background-color: #f4f4f4;
          border-radius: 10px;
          border: 1px solid #c1c1c1;
          // width: 407px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // p {
          //     color: var(--background-color);
          //     font-size: 12px;
          //     padding: 0px 0px 0px 18px;
          //     font-family: var(--poppins-semibold);

          // }

          .tr_details {
            float: right;

            .tr_shareInfo {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .tr_bloodTestDate {
              font-size: 14px;
              color: #000000;
              font-family: var(--poppins-regular);
              margin: 16px 14px 15px auto;
            }

            // p {
            //     color: #000000;
            //     font-size: 12px;
            //     margin-right: 14px;
            //     font-family: var(--poppins-semibold);
            // }
          }
        }
      }
    }

    .viewSample {
      font-size: 18px;
      color: var(--background-color);
      font-family: var(--poppins-semibold);
      margin: 0;
    }
  }
}

.contaninerBg {
  background-color: #f3f2f4;
}

.review_title {
  font-size: 18px;
  color: var(--background-color);
  font-family: var(--poppins-semibold);
}

.tr_arrowIcon {
  background-color: #405973 !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 17px 19px 14px 0px;
  text-align: center;
}

.mainProfile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  margin-top: 27px;
}

.allReports {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
}

.section {
  display: grid;
  gap: 17px;
  grid-template-columns: repeat(3, 1fr);

  @media (min-width: 768px) and (max-width: 1024px) {
    // grid-template-columns: repeat(2, 1fr);
    display: block;
  }

  .section_para {
    display: block;
    // height: 100%;
    background: #fff;
    border-radius: 15px;
    margin-top: 18px;
    // background-color: #fff;
  }
}

.overview_main {
  background-color: white !important;
  border-radius: 15px !important;
  padding: 25px !important;
  box-shadow: none !important;
  width: 445px;
}

.organ_title {
  font-size: 22px;
  color: var(--background-color);
  font-family: var(--poppins-medium);
  margin-bottom: 0;
  background-color: #f3f2f4;
  margin-left: 25px;
  margin-top: 35px;
}

.report_summary_title {
  font-size: 22px;
  color: var(--background-color);
  font-family: var(--poppins-medium);
  background-color: #f3f2f4;
  margin-bottom: 0;
  margin-left: 25px;
  margin-top: 35px;
}
.report_summary_title_consumer{
  font-size: 22px;
  color: var(--background-color);
  font-family: var(--poppins-medium);
  background-color: #f3f2f4;
  margin-bottom: 17px;
  margin-top: 35px;
}

.insights_title {
  color: var(--background-color);
  font-size: 22px;
  font-family: var(--poppins-medium);
  background: #f3f2f4;
  margin-bottom: 0;
  margin-left: 25px;
  margin-top: 35px;
}

.summaryTitle {
  color: #122d5b;
  font-family: var(--poppins-medium);
  font-size: 22px;
  margin-top: 73px;
  margin-bottom: 26px;
}

.biomarkerSection {
  display: grid;
  grid-template-columns: auto auto auto;

  @media (min-width: 768px) and (max-width: 1024px) {
    // grid-template-columns: auto;
    // width: fit-content;
    display: block;
  }
.biomarkerSection_role{
  display: grid;
  gap: 17px;
  margin-bottom: 58px;
}
  @media (min-width: 957px) and (max-width: 1267px) {
    // grid-template-columns: auto auto;
    // width: fit-content;
  }

  grid-auto-flow: row;
  gap: 17px;
  margin-bottom: 58px;
}

.tr_trendReport {
  border-radius: 10px;
  border: 1px solid var(--background-color);
  // width: 407px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;

  .tr_title_details {
    font-size: 16px;
    color: var(--background-color);
    font-family: var(--poppins-semibold);
    margin: 15px 9px 13px 10px;
  }

  .tr_date {
    font-size: 14px;
    color: #000000;
    font-family: var(--poppins-regular);
    margin: 14px 25px 12px auto;
  }

  .tr_details {
    float: right;

    .tr_shareInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrowIcon {
        background-color: #405973;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 19px;
        text-align: center;
      }
    }
  }
}

.slider_Icon {
  position: absolute;
  top: 45%;
  right: -19px;
  // backgroundColor="white"
  cursor: pointer;
  padding: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  //   color: var(--background-color);
  //   border-radius: 50%;
}

.slider_prev_Icon {
  position: absolute;
  top: 45%;
  left: -19px;
  // backgroundColor="white"
  cursor: pointer;
  padding: 0;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  // color: white;
  // border-radius: 50%;
}

.bloodTest_main {
  display: grid;
  grid-template-columns: auto auto auto;

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: auto auto;
  }

  gap: 16px;
}

.bloodTest_mainReport {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
  margin-top: 20px;
}

.noDataFound {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  .noData {
    font-size: 25px;
    color: var(--background-color);
    padding: 69px;
    display: flex;
    justify-content: center;
    font-family: var(--poppins-semibold);
  }
}

.no_profileAvailable {
  color: #000000 !important;
  font-size: 16px !important;
  margin: auto;
  text-align: center;
  width: 100%;
  padding-block: 39px;
  font-family: var(--poppins-semibold) !important;
}

.findClick {
  cursor: pointer;
  width: 100%;
  padding-block: 12px;
  padding-left: 25px;
  background-color: #F4F4F4;
  border-radius: 15px;
  color: #122D5B;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.findCard {
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
  padding: 1px 30px 30px 19px;
  p {
    color: var(--background-color);
    font-size: 18px;
    font-family: var(--poppins-semibold);
  }
}

.noReport {
  color: var(--background-color);
  font-size: 18px;
  font-family: var(--poppins-semibold);
}

.sectionSet {
  // gap: 25px;
  // width: 445px;
  // height: 572px;
  // overflow-y: scroll;
  background-color: #fff;
  padding: 19px 0 19px 37px;
  margin-top: 17px;
  border-radius: 15px;
  // display: grid;
  // grid-template-columns: auto auto;
  flex: 1 1 auto;
}