@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Poppins-Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("./assets/fonts/Poppins-Black.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("./assets/fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraLigtItalic";
  src: url("./assets/fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("./assets/fonts/Poppins-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("./assets/fonts/Poppins-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("./assets/fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("./assets/fonts/Poppins-Italic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("./assets/fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./assets/fonts/Poppins-Thin.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("./assets/fonts/Poppins-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("./assets/fonts/Poppins-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("./assets/fonts/Poppins-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

:root {
  --background-color: #122d5b;
  --font-family: "Poppins", sans-serif;
  --poppins-semibold: "Poppins-Semibold", sans-serif;
  --poppins-bold: "Poppins-Bold", sans-serif;
  --poppins-black: "Poppins-Black", sans-serif;
  --poppins-extraBold: "Poppins-ExtraBold", sans-serif;
  --poppins-extraLight: "Poppins-ExtraLight", sans-serif;
  --poppins-extraLightItalic: "Poppins-ExtraLigtItalic", sans-serif;
  --poppins-blackItalic: "Poppins-BlackItalic", sans-serif;
  --poppins-boldItalic: "Poppins-BoldItalic", sans-serif;
  --poppins-extraBoldItalic: "Poppins-ExtraBoldItalic", sans-serif;
  --poppins-italic: "Poppins-Italic", sans-serif;
  --poppins-semiBoldItalic: "Poppins-SemiBoldItalic", sans-serif;
  --poppins-thin: "Poppins-Thin", sans-serif;
  --poppins-lightItalic: "Poppins-LightItalic", sans-serif;
  --poppins-regular: "Poppins-Regular", sans-serif;
  --poppins-light: "Poppins-Light", sans-serif;
  --poppins-mediamItalic: "Poppins-MediumItalic", sans-serif;
  --poppins-medium: "Poppins-Medium", sans-serif;
  --poppins-thinItalic: "Poppins-ThinItalic", sans-serif;
}
body {
  font-family: "Poppins", sans-serif;
}

.common-btn {
  background-color: var(--background-color);
  font-family: var(--font-family);
  color: white;
  border-radius: 28px;
  font-size: 12px;
  padding: 14px 12px;
  cursor: pointer;
  border: none;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #d1d1d1 !important;
  border-width: 0px;
}
.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}
input {
  font-family: "Poppins" !important;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px lightgray; */
  display: none;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(189, 189, 189, 0.705);
  border-radius: 10px;
  border-color: #d1d1d1 !important;
  border-width: 0px;
}

.MuiOutlinedInput-input {
  padding: 14px 12px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.MuiToolbar-regular {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .MuiAccordion-root{
  border-radius: 15px !important;
} */
#drawerComponent .MuiIconButton-label {
  background-color: white !important;
}
.MuiAccordion-rounded {
  border-radius: none !important;
  box-shadow: none !important;
}
.MuiAccordion-root::before {
  background-color: #fff !important;
}
/* .section-accordian .MuiPaper-root{
  background-color: transparent !important;
} */
.section-accordian .MuiAccordionDetails-root {
  display: block !important;
  background-color: transparent !important;
  border-top: 0px !important;
}
.section-accordian .MuiAccordion-root {
  background-color: transparent !important;
}
.accordian_detail .MuiAccordionSummary-content {
  margin: 16px 0 12px 0 !important;
}
.accordian_detail .MuiAccordionSummary-expandIcon {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.accordian_detail .MuiAccordionSummary-content.Mui-expanded {
  margin: 16px 0 12px 0 !important;
}
.accordian_detail .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.section-accordian .MuiAccordionSummary-root.Mui-expanded {
  /* background-color: #f3f2f4 !important; */
  min-height: 0 !important;
}
/* .section-accordian .MuiAccordionSummary-root{
  background-color: #F3F2F4 !important;
} */
.accordian .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: outset -1px 5px 9px -5px #b8b0b05c !important;
  /* box-shadow: none !important; */
}
.header-main .MuiTouchRipple-root {
  display: none !important;
}
.menuItem .MuiPaper-rounded {
  border-radius: 15px !important;
}
.menuItem .MuiMenu-paper {
  border-radius: 15px !important;
}
.slick-next {
  color: #122d5b !important;
  right: -14px !important;
}
.slick-prev {
  color: #122d5b !important;
  left: -14px !important;
}
.slick-prev::before {
  color: #122d5b !important;
}
.slick-next::before {
  color: #122d5b !important;
}
.react-tel-input .flag {
  /* background-image: url("./assets/svg/phone.svg") !important; */
  /* background-size: contain;
  background-position: 0 !important;
  position: relative !important;
  left: 10px !important;
  width: 14px !important; */
}
.react-tel-input .flag-dropdown .country-list .country .country-name,
.react-tel-input .flag-dropdown .country-list .country .dial-code {
  position: relative !important;
  top: -4px !important;
}
.react-tel-input .flag-dropdown .country-list .country .flag {
  position: static !important;
}
.react-tel-input .flag-dropdown .country-list {
  left: 10px !important;
}
/* .react-tel-input .flag-dropdown {
  width: 118px !important;
}
.react-tel-input .selected-flag .arrow {
  position: relative !important;
  left: 79px !important;
} */
.react-tel-input .selected-flag::before {
  transition: none !important;
}

@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1451px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.MuiContainer-root {
  max-width: 1440px !important;
  padding-inline: 30px !important;
}
.react-tel-input .country-list {
  overflow-x: hidden !important;
  width: 400px !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15) !important;
  font-family: "Poppins";
}

.react-tel-input .country-list > li.search input.search-box {
  border: 1px solid #cacaca !important;
  border-radius: 3px !important;
  height: 40px !important;
  border-radius: 10px !important;
  border-color: rgb(209, 209, 209) !important;
  border-width: 0.5px !important;
  width: 100% !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.react-tel-input .country-list > li.country {
  width: 384px !important;
  margin: 0;
  padding: 7px;
  position: relative;
  left: 10px;
  border-radius: 6px;
}

.react-tel-input .country-list > li.country.highlight {
  background-color: #9f9d9d !important;
  color: white !important;
}

.react-tel-input .country-list > li.country.highlight .dial-code {
  color: white !important;
}

.react-tel-input .country-list .no-entries-message {
  padding: 7px 11px 11px 24px !important;
}

.react-tel-input .selected-flag.open:before {
  border: none !important;
  box-shadow: none !important;
}
.react-tel-input .selected-flag:focus:before {
  border: none !important;
  box-shadow: none !important;
}

#login-page-BgImage {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("./assets/images/AHABGimage.jpeg");
  height: 100vh;
  width: 100%;
  background-size: cover;
}
.input-error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 19px;
  font-family: var(--poppins-medium);
}
.commonSummaryError{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--poppins-semibold) !important;
  color: var(--background-color) !important;
  font-size: 18px !important;
}
.organOverview .MuiAccordion-root.Mui-expanded{
  margin: 0px !important;
}