.listItem {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  /* width: 328px; */
  border: 1px solid lightgray;
  padding-top: 18px;
  padding-bottom: 10px;
  border-top: none;
  /* top: 131px; */
  z-index: 99;
  border-radius: 15px;
  background: #fff;
  /* right: 34px; */
  left: 31px;
  width: 100%;
  //width: 50%;
  top: 122px;
  padding-left: 30px;

  .createNewPatient {
    cursor: pointer;
  }
}

.grid_content {
  margin-bottom: 5px;
}

.menuItem {
  // margin-bottom: 10px;
  font-size: 14px;
  color: #122d5b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.birthDateMenu {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inputField {
  width: 100%;
  //width: 50%;
  padding-block: 12px;
  padding-left: 25px;
  background-color: #f4f4f4;
  border-radius: 15px;
  color: #122d5b;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid lightgray;

  &:focus-visible {
    outline: none;
  }
}

// .mainDiv {
//   justify-content: center;
//   align-items: center;
//   display: flex;
// }

.grid_container {
  display: flex;
  padding-inline: 10px;
  align-items: center;
}

.image1 {
  width: 40px;
  height: auto;
  grid-area: image1;
}
.grid_content{
  cursor: pointer;
}
.paraLink {
  color: var(--background-color);
  font-size: 18px;
  font-family: var(--poppins-semibold);
}