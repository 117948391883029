body {
  background-color: #f3f2f4;
  font-family: var(--poppins-normal) !important;
}
.contaninerBg {
  min-height: calc(100vh - 116px);
  .breadcrumbs {
    margin-bottom: 80px;
  }
  .profileName {
    max-width: 1440px !important;
    padding-inline: 30px !important;
    margin: auto auto 15px auto;
    font-family: var(--poppins-bold);
    color: #122d5b;
  }
  .container {
    height: calc(100vh - 116px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .trendsGraphReport,
    .navigationButtons {
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: 1rem 0;

      .reportBox {
        padding: 2rem 2.5rem;
        // max-width: 674px;
        // width: 674px;
        border-radius: 15px;
        background-color: #ffffff;
        height: min-content;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .reportsHeading {
          margin-bottom: 60px;
          h3 {
            font-size: 22px;
            color: #122d5b;
            font-family: var(--poppins-medium);
            margin: 0;
            user-select: none;
          }
        }
        .accordionContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
        }
        .BiomarkerContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            .reportsHeading {
              margin-bottom: 0;

              h3 {
                font-size: 22px;
                color: #122d5b;
                font-family: var(--poppins-medium);
                margin-bottom: 0;
              }
            }
            .select {
              label {
                text-transform: capitalize;
                font-size: 14px;
                color: black;
                font-family: var(--poppins-medium);
                margin-right: 10px;
                user-select: none;
              }
              .dropDown {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                padding: 9px;
                border-radius: 27px;
                color: #182d5b;
                font-size: 17px;
                font-family: var(--poppins-medium);
              }
              .dropDown::before {
                content: unset;
              }
            }
          }
          .dateContainer {
            border-top: 1px solid #d6d6d6;
            border-bottom: 1px solid #d6d6d6;
            padding: 10px;
            display: flex;
            // justify-content: space-between;
            align-items: center;

            .datesWrapper {
              width: 71%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media (min-width: 1921px) {
                width: 72%;
              }
              @media (min-width: 1708px) and (max-width: 1920px) {
                width: 75%;
              }
              @media (min-width: 1398px) and (max-width: 1707px) {
                width: 71%;
              }
              @media (min-width: 1230px) and (max-width: 1397px) {
                width: 75%;
              }
              @media (min-width: 1153px) and (max-width: 1229px) {
                width: 76%;
              }
              @media (min-width: 1099px) and (max-width: 1152px) {
                width: 77%;
              }
              @media (min-width: 1025px) and (max-width: 1098px) {
                width: 78%;
              }
              @media (min-width: 767px) and (max-width: 1024px) {
                width: 71%;
              }
              button {
                // position: absolute;
                border: none;
                outline: none;
                background-color: unset;
                cursor: pointer;
                user-select: none;
              }
              .date {
                user-select: none;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                span {
                  font-size: 14px;
                  max-width: max-content;
                  font-family: var(--poppins-regular);
                }
              }
            }
            .calenderIcon {
              width: 22%;
              cursor: pointer;
              @media (min-width: 1099px) and (max-width: 1152px) {
                width: 21%;
              }
              @media (min-width: 1025px) and (max-width: 1098px) {
                width: 20%;
              }
              @media (min-width: 767px) and (max-width: 1024px) {
                width: 24%;
              }
            }
          }
          .reportsContainer {
            margin-top: 1rem;
          }
        }
      }
      .navigationButton {
        max-width: 175px;
        padding: 10px;
        font-size: 16px;
        text-transform: capitalize;
        border-color: #122d5b;
        color: #122d5b;
        max-height: 42px;
      }
      .navigationButton:hover {
        color: white;
      }
    }
  }
  .goToHomeButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    button {
      padding: 11px 35px;
      font-family: "Poppins-Regular";
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      outline: none;
      background-color: transparent;
      height: 42px;
      font-weight: 400;
      color: #122d5b;
      border: 1px solid #122d5b;
      font-size: 1rem;
      border-radius: 33px;
    }
  }
}

@media (max-width: 2048px) {
  .contaninerBg {
    .breadcrumbs {
      margin-bottom: 60px;
    }
    .container {
      .trendsGraphReport {
        padding: 0 0;
        padding-bottom: 2rem;
        .reportBox {
          padding: 1.8rem 2.3rem;
          .reportsHeading {
            margin-bottom: 50px;
          }
          .BiomarkerContainer {
            .header {
              margin-bottom: 33px;
              .select {
                .dropDown {
                  padding: 5px 10px;
                  font-size: 16px;
                  font-family: var(--poppins-medium);
                }
              }
            }
          }
        }
      }
    }
    .navigationButtons {
      margin-top: 10px;
    }
  }
}

@media (max-width: 1919px) {
  .contaninerBg {
    .breadcrumbs {
      margin-bottom: 50px;
    }
    .container {
      .trendsGraphReport {
        .reportBox {
          padding: 1.4rem 2rem;
          // max-width: 650px;
          width: 100%;
          .reportsHeading {
            margin-bottom: 40px;
            h3 {
              font-size: 19px;
            }
          }
          .BiomarkerContainer {
            .header {
              margin-bottom: 30px;
              .reportsHeading {
                h3 {
                  font-size: 19px;
                }
              }
            }
            .dateContainer {
              .calenderIcon {
              }
              .datesWrapper {
                .date {
                  span {
                    font-size: 13px;
                    font-family: var(--poppins-regular);
                  }
                }
              }
            }
            .reportsContainer {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
    .navigationButtons {
      .navigationButton {
        max-width: 146px;
        padding: 8px !important;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 1707px) {
  .contaninerBg {
    .breadcrumbs {
      margin-bottom: 50px;
    }
    .container {
      .trendsGraphReport {
        .reportBox {
          padding: 1.4rem 2rem;
          // max-width: 590px;
          // width: 590px;
          .reportsHeading {
            margin-bottom: 33px;
            h3 {
              font-size: 17px;
            }
          }
          .accordionContainer {
            gap: 0.7rem;
          }
          .BiomarkerContainer {
            .header {
              margin-bottom: 23px;
              .reportsHeading {
                h3 {
                  font-size: 17px;
                }
              }
              .select {
                label {
                  font-size: 11px;
                }
                .dropDown {
                  padding: 2px 7px;
                  font-size: 12px;
                  width: 100px;
                }
              }
            }
            .dateContainer {
              .datesWrapper {
                button {
                }
                .date {
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
            .reportsContainer {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
    .navigationButtons {
      .navigationButton {
        max-width: 127px !important;
        padding: 8px;
        font-size: 14px !important;
        max-height: 32px !important;
      }
    }
  }
}
@media (max-width: 1535px) {
  .contaninerBg {
    .breadcrumbs {
      margin-bottom: 50px;
    }
    .container {
      .trendsGraphReport {
        .reportBox {
          // max-width: 600px;
          // width: 600px;
          .reportsHeading {
            h3 {
              font-size: 1rem;
            }
          }
          .accordionContainer {
          }
          .BiomarkerContainer {
            .header {
              .reportsHeading {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .contaninerBg {
    .breadcrumbs {
      margin-bottom: 50px;
    }
    .container {
      .trendsGraphReport {
        flex-direction: column;
        .reportBox {
          max-width: 100%;
          width: 100%;
          .reportsHeading {
            margin-bottom: 20px;
            h3 {
              font-size: 1rem;
            }
          }
          .BiomarkerContainer {
            .header {
              .reportsHeading {
                h3 {
                  font-size: 1rem;
                }
              }
              .select {
                label {
                  font-size: 14px;
                }
                .dropDown {
                  width: 120px;
                  height: 40px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .navigationButtons {
      .navigationButton {
        max-width: 115px !important;
        font-size: 12px !important;
        max-height: 32px !important;
      }
    }
  }
}
