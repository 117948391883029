.contaninerBg {
    background-color: #f3f2f4;
    padding-bottom: 42px;
}

.btns {
    display: flex;
    gap: 56px;
    padding-top: 47px;
    padding-inline: 30px;
    align-items: center;
    color: #122D5B;
    font-family: var(--poppins-medium);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btnsArrow {
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
}

.breadCrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report_Title {
    color: #122D5B;
    font-family: var(--poppins-semibold);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}