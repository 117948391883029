.insights_main_box {
  box-shadow: none !important;
  border-radius: 15px !important;
  background-color: white !important;
  padding: 20px;
  padding-inline: 25px 50px !important;
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-inline: 20px !important;
  }

  .seek_medical {
    p {
      font-size: 18px;
      color: var(--background-color);
      font-family: var(--poppins-medium);
      margin-top: 3px;
      margin-bottom: 9px;
    }

    .seek_info {
      color: #000000;
      font-size: 16px;
      font-family: var(--poppins-regular);
      margin-bottom: 33px;
    }

    .insightsImage {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 21px;
    }
  }

  .diet_info {
    margin-top: 51px;
    p {
      font-size: 18px;
      color: var(--background-color);
      font-family: var(--poppins-medium);
      margin-top: 0;
      margin-bottom: 10px;
    }

    .diet_details {
      font-size: 16px;
      font-family: var(--poppins-regular);
      color: #000000;
    }

    .diet_details2 {
      font-size: 16px;
      font-family: var(--poppins-regular);
      color: #000000;
      margin-top: 25px;
    }
  }
}
.insightsMain {
  // height: 100%;
  background-color: #fff;
  border-radius: 15px;
}
