.patient_main {
  background-color: white;
  border-radius: 15px !important;
  box-shadow: none !important;

  .patient_grid {
    display: flex;
    /* gap: 17px; */
    flex-wrap: wrap;
    justify-content: space-between;

    .cardBorder {
      width: 1px;
      height: 137px;
      background-color: #f4f4f4;
      margin-top: 32.5px;
    }

    .information {
      // padding: 32px 0px 36px 25px;
      // width: 290px;
      padding: 32px 20px 32px 20px;
      width: 33%;

      p {
        font-size: 20px;
        // font-weight: 600;
        color: var(--background-color);
        font-family: var(--poppins-medium);
        margin: 0;
        margin-bottom: 20px;
      }

      .pt_container {
        .pt_name {
          display: flex;
          // justify-content: space-between;
          width: 100%;
          // gap: 24px;
          gap: 100px;

          .name_label {
            color: #8a8a8a;
            font-size: 16px;
            font-family: var(--poppins-regular);
            margin-bottom: 10px;
          }

          .patient_name {
            color: var(--background-color);
            font-size: 16px;
            font-family: var(--poppins-medium);
            margin-bottom: 10px;
          }
        }
      }
    }

    .test_info_main {
      // width: 250px;
      // padding: 10px 15px;
      padding: 10px 33px;
      width: 33%;

      p {
        font-size: 20px;
        color: var(--background-color);
        font-family: var(--poppins-medium);
      }

      .test_info {
        display: grid;
        grid-template-columns: auto auto;
        line-height: 0;
        gap: 15px;

        .test_date_label {
          color: #8a8a8a;
          font-size: 16px;
          font-family: var(--poppins-regular);
        }

        .test_date {
          color: var(--background-color);
          font-size: 16px;
          font-family: var(--poppins-medium);
        }
      }
    }

    .inter_main {
      // width: 500px;
      // padding: 10px 26px;
      // border-radius: 15px;
      // box-shadow: none;
      padding: 10px 26px;
      border-radius: 15px;
      box-shadow: none;
      width: 33%;

      p {
        font-size: 20px;
        color: var(--background-color);
        font-family: var(--poppins-medium);
      }

      .inter_details {
        color: #000000;
        font-size: 16px;
        font-family: var(--poppins-regular);
      }
    }
  }
}
