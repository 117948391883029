.contaninerBg {
  background-color: #f3f2f4;

  & > .MuiContainer-root{
    max-width: 1440px !important;
    padding-inline: 30px !important;
  }
}

.mainScreen {
  background-color: #ffffff;
  border-radius: 15px !important;
  padding: 10px 30px 10px 19px !important;
  box-shadow: none !important;
  margin-top: 24px;

  h4 {
    color: #122D5B;
    font-size: 22px;
    font-family: var(--poppins-medium);
    margin: 24px 0 14px 6px;
  }

  p {
    color: #000000;
    font-size: 16px;
    font-family: var(--poppins-regular);
    margin: 0 0 0 6px;
  }

  .inputText {
    border: 1px solid #D1D1D1;
    border-radius: 15px;
    color: #8A8A8A;
    max-width: 80px;
    height: 50px;
    // padding-left: 30px;
    text-align: center;

    &:focus-visible {
      outline: none;
    }

  }

  .inputLabel {
    display: inline-grid;
    margin: 30px 0 0 6px;
  }

  label {
    padding-bottom: 16px;
    color: #122D5B;
    font-family: var(--poppins-medium);
    font-size: 16px;
  }
}

.manage_section {
  display: flex;
  align-items: center;
  gap: 16px;

  .select_dropdown {
    border: none;
    color: #E68413;
    font-size: 16px;
    width: 64px;
    font-family: var(--poppins-regular);

    &:focus-visible {
      outline: none;
    }
  }

  .select_dropdown_inches {
    border: none;
    color: #E68413;
    font-size: 16px;
    width: 80px;
    font-family: var(--poppins-regular);

    &:focus-visible {
      outline: none;
    }
  }
}

.manage_dropdown {
  display: flex;
  gap: 51px;
}

.main_element_section {
  display: flex;
  gap: 72px;

  .select_dropdown_lbs {
    border: none;
    color: #E68413;
    font-size: 16px;
    width: 52px;
    font-family: var(--poppins-regular);

    &:focus-visible {
      outline: none;
    }
  }
}

.lineStyle {
  border-top: 1px #D1D1D1;
  margin-inline: 6px;
  margin-top: 32px;
}

.allergies_input {
  padding: 24px 0 23px 24px;
  color: #8A8A8A;
  font-size: 16px;
  font-family: var(--poppins-regular);
  border: 1px solid #D1D1D1;
  border-radius: 15px;
  width: 426px !important;

  &:focus-visible {
    outline: none;
  }
}

.second_label {
  display: inline-grid;
  margin: 16px 0 0 0;
}

.next_section {
  margin: 34px 0 34px 6px;

  label {
    color: #122D5B;
    font-size: 22px;
    font-family: var(--poppins-medium);
  }
  .addNewBtn{
    color: #E68413;
    font-size: 16px;
    font-family: var(--poppins-semibold);
    border: none;
    background-color: transparent;
    margin-top: 27px;
    margin-left: 25px;
    cursor: pointer;
  }
}
.lineStyleNext{
  border-top: 1px #D1D1D1;
  margin-inline: 6px;
  margin-top: 34px;
}
.diseases_section{
  margin: 34px 0 46px 6px;
  label{
    color: #122D5B;
    font-size: 22px;
    font-family: var(--poppins-medium);
  }
  .addNewBtn{
    color: #E68413;
    font-size: 16px;
    font-family: var(--poppins-semibold);
    border: none;
    background-color: transparent;
    margin-top: 17px;
    margin-left: 25px;
   cursor: pointer;
  }
}
.second_label_next{
  display: flex;
  gap: 20px;
  align-items: center;
  // padding-bottom: 20px;
  padding-top: 16px;
  // margin-top: 46px;
}
.label_align{
  display: inline-grid;
  label{
    font-size: 16px;
    color: #122D5B;
    font-family: var(--poppins-medium);
    margin-left: 23px;
  }
}
.when_input{
  padding: 24px 0 23px 24px;
  color: #8A8A8A;
  font-size: 16px;
  font-family: var(--poppins-regular);
  border: 1px solid #D1D1D1;
  border-radius: 15px;
  width: 322px !important;

  &:focus-visible {
    outline: none;
  }
}
.comment_input{
  padding: 24px 0 23px 24px;
  color: #8A8A8A;
  font-size: 16px;
  font-family: var(--poppins-regular);
  border: 1px solid #D1D1D1;
  border-radius: 15px;
  width: 535px !important;

  &:focus-visible {
    outline: none;
  }
}
.removeBtn{
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  button{
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
}
.BottomNextBtn {
  display: flex;
  justify-content: center;
  padding-block: 47px 47px;
  gap: 10px;
}
.labelInfo{
  display: flex;
  justify-content: space-between;
}
.top_section{
  // margin-top: 46px;
}
.inchesText{
  color: #E68413;
  font-size: 16px;
  font-family: var(--poppins-regular);
}
.inchesText{
  color: #E68413;
  font-size: 16px;
  font-family: var(--poppins-regular);
}
@media (min-width: 768px) and (max-width: 1024px) {
  .second_label_next{
    // flex-direction: column;
    align-items: inherit;
  }
  .diseases_section input{
    width: 100% !important;
    padding-left: 15px;
  }
 .diseases_section input::placeholder {
    font-size: 14px;
}
.second_label input::placeholder {
  font-size: 14px;
}
.second_label input {
  padding-left: 15px;
}

}