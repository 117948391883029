.dropdownHeading {
  margin: 0;
  color: #122d5b;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
  padding-left: 24px;
}
.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dropdownBtn {
  min-width: 284px;
  border-radius: 15px;
  border: 1px solid #d1d1d1;
  padding: 18px;
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
  height: 60px;
  position: relative;
  cursor: pointer;
}
.dropdownArrow {
  position: absolute;
  top: 18px;
  right: 18px;
  transition: all 0.4s ease-out;
}

.dropdownArrowOepn {
  transform: rotate(180deg);
  transition: all 0.4s ease-in;
}
.dropdownContent {
  position: absolute;
  top: 60px;
  background: white;
  border-radius: 10px;
  width: 100%;
  left: 0;
  box-shadow: 0 0 5px 0 #e2e2e2;
  overflow: auto;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  & ul {
    padding: 14px;
    margin: 0;
    // max-height: 206px;
    & > li {
      text-decoration: none;
      list-style-type: none;
      border-bottom: 1px solid rgba(236, 236, 236, 0.781);
      padding-block: 10px;
      padding: 12px;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #e4e3e3;
        color: black;
        border-radius: 15px;
      }
    }
  }
}
.dropdownContentOpen {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
  display: block;
}

.isListValueSelected {
  background: #122d5b;
  color: white;
  border-radius: 15px;
}
.isListValueSelectedUnit {
  background: #eaeaea;
  padding: 7px;
  cursor: pointer;
  width: 95px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  text-align: center;
  font-family: var(--poppins-regular);
}
.dropdown {
  margin: 0 auto;
  // z-index: 10000;

  position: relative;
  text-align: right;
}
// .dropdown,
// .dropdown * {
//   // z-index: 10;
//   line-height: 0.6;
// }
.dropdown .dropdown_btn {
  cursor: pointer;
  // background: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // gap:7px;
  // padding: 10px;
  // background: white;
  border-radius: 5px;
  color: #e68413;
  font-size: 16px;
  font-family: var(--poppins-regular);
  // width: 95px;
  // line-break: anywhere;
}
.dropdown_content {
  position: absolute;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  // width: 100%;
  width: 95px;
  z-index: 1;
}
.dropdown_content .item {
  padding: 7px;
  cursor: pointer;
  width: 95px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  text-align: center;
  font-family: var(--poppins-regular);
  // text-align: initial;
} 
.dropdown_content .item:hover {
  background: #fcfcfc;
}
.menuTitle {
  // width: 40px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.arrowIcon {
  margin-top: 5px;
}
