.content {
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 400;
  // max-width: 350px;
  max-width: 343px;
  margin-block: 0 !important;
  font-family: var(--poppins-regular);
}

.signupBtn {
  border-radius: 33px;
  background-color: var(--background-color) !important;
  font-family: var(--font-family) !important;
  color: white !important;
  font-weight: bold !important;
  border: none !important;
  // width: 100%;
  width: 350px !important;
  font-size: 18px !important;
  line-height: 25px !important;
  padding-block: 20px !important;
  padding-inline: 0 !important;
  display: flex !important;
  justify-content: center !important;
  cursor: pointer;
  text-align: center;
  margin: 0 !important;
  margin-top: 34px !important;
  @media (max-height: 695px) {
    font-size: 16px !important;
    padding-block: 11px !important;
    margin-top: 22px !important;
  }
  @media (min-height: 696px) and (max-height: 764px) {
    padding-block: 13px !important;
    margin-top: 29px !important;
  }
  @media (min-height: 765px) and (max-height: 833px) {
    padding-block: 15px !important;
    margin-top: 32px !important;
  }
}
.addOtherBtn {
  border-radius: 33px !important;
  background-color: var(--background-color) !important;
  font-family: var(--font-family) !important;
  color: white !important;
  font-weight: bold !important;
  border: none !important;
  width: 100%;
  font-size: 18px !important;
  line-height: 25px !important;
  padding: 14px 12px !important;
  cursor: pointer;
  text-align: center;
  margin-top: 20px !important;
  margin-bottom: 36px !important;
  display: flex !important;
  justify-content: center !important;
  @media (max-height: 695px) {
    font-size: 16px !important;
  }
}
.haveAcc {
  color: #000000;
  font-size: 16px;
  margin-block: 16px 38px;
  font-family: var(--poppins-regular);
  transform: translate(-3px, 128px);
  // @media (max-height: 695px) {
  //   margin-block: 11px 25px;
  // }
  // @media (min-height: 696px) and (max-height: 764px) {
  //   margin-block: 13px 31px;
  // }
  // @media (min-height: 765px) and (max-height: 833px) {
  //   margin-block: 13px 34px;
  // }
  display: flex;
  justify-content: center;
  span {
    font-weight: bold;
    color: #122d5b;
    font-size: 16px;
    margin-left: 5px;
    cursor: pointer;
  }
}
.checkboxContent {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-block: 26px 0;
  @media (max-height: 695px) {
    margin-block: 8px 0;
  }
  @media (min-height: 696px) and (max-height: 764px) {
    margin-block: 8px 0;
  }
  @media (min-height: 765px) and (max-height: 833px) {
    margin-block: 8px 0;
  }
}
.signupTitle {
  display: flex;
  justify-content: center;
  color: #122d5b !important;
  font-weight: bold !important;
  font-family: var(--font-family);
  margin-bottom: 0;
  font-size: 32px;
  margin-top: 42px;
  margin-bottom: 27px;
  @media (max-height: 695px) {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 26px;
  }
  @media (min-height: 696px) and (max-height: 764px) {
    margin-top: 34px;
    margin-bottom: 21px;
    font-size: 28px;
  }
  @media (min-height: 765px) and (max-height: 833px) {
    margin-top: 38px;
    margin-bottom: 24px;
    font-size: 30px;
  }
}
.closebtn {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.errorMessage {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 19px;
  font-family: var(--poppins-regular);
}
.errorMessageForCheckBox {
}
.inputError {
  border: 0.5px solid red !important;
  border-radius: 33px;

  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  // top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  // text-align: left;
  // position: absolute;
  // bottom: 0;
  // right: 0;
  // left: 0;
  // margin: 0;
  // padding: 0 8px;
  // border-radius: 33px;
  // border-width: 1px;
  // overflow: hidden;
  // min-width: 0%;

  &:hover {
    border-color: red !important;
  }
}
.radioButton {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  .MuiButtonBase-root-MuiRadio-root {
    color: #d1d1d1;
  }
}
.phoneInputError {
  border: 0.5px solid red !important;
  border-radius: 33px !important;

  text-align: left !important;
  position: relative !important;
  bottom: 0 !important;
  right: 0 !important;
  // top: -5px;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 1px !important;
  overflow: hidden !important;
  min-width: 0% !important;
  overflow: visible !important;
  width: 413px !important;
  & input {
    border: none !important;
    border-radius: 50% !important;
    height: 65px !important;
    @media (max-height: 695px) {
      height: 50px !important;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      height: 50px !important;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      height: 55px !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.phoneInputErrorClass {
  border: 0.5px solid red !important;
  border-radius: 33px !important;

  text-align: left !important;
  position: relative !important;
  bottom: 0 !important;
  right: 0 !important;
  // top: -5px;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 1px !important;
  overflow: hidden !important;
  min-width: 0% !important;
  overflow: visible !important;
  width: 350px !important;
  & input {
    border: none !important;
    border-radius: 50% !important;
    height: 65px !important;
    @media (max-height: 695px) {
      height: 50px !important;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      height: 50px !important;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      height: 55px !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.phoneInput {
  input {
    border-radius: 33px !important;
    border-color: rgb(209, 209, 209) !important;
    border-width: 0.5px !important;
    width: 100% !important;
    // width: 350px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    height: 65px !important;
    @media (max-height: 695px) {
      height: 50px !important;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      height: 50px !important;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      height: 55px !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.phoneInputClass {
  input {
    border-radius: 33px !important;
    border-color: rgb(209, 209, 209) !important;
    border-width: 0.5px !important;
    //width: 100% !important;
    width: 350px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    height: 65px !important;
    @media (max-height: 695px) {
      height: 40px !important;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      height: 40px !important;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      height: 40px !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.react-tel-input .selected-flag.open:before {
  border-color: none !important;
  box-shadow: none !important;
}
.passwordShow{
  cursor: pointer;
}
.dropdownGender{
  border-radius: 33px;
    border-color: rgb(209, 209, 209);
    border-width: 0.5px;
    width: 414px;
    height: 65px;
    padding: 0 20px 0 20px;
}
.App {
  font-family: sans-serif;
}

.dropdown {
  // margin: 0 auto;
  // z-index: 10000;
  width: 300px;
  position: relative;
}
.dropdown,
.dropdown * {
  // z-index: 10;
}
.dropdown .dropdown_btn {
  cursor: pointer;
  // background: rgb(177, 146, 146);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  color: #8A8A8A;
  font-family: var(--poppins-medium);
  font-weight: 500;
  border-radius: 33px;
    border: 1px solid rgb(209, 209, 209);
    border-width: 0.5px;
    width: 414px;
    height: 55px;

    @media (max-height: 695px){
      height: 50px;
    };
    @media (min-height: 696px) and (max-height: 764px){
      height: 50px;
    };
    @media (min-height: 765px) and (max-height: 833px) {
      height: 55px;
    };
}
.dropdown_content {
  position: absolute;
  background-color: #fff;
  /* top: 110%; */
  left: 0;
  width: 100%;
  z-index: 99;
  width: 414px;
  border-radius: 15px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  overflow: scroll;
  height: 100px;
}
.dropdown_content .item {
  padding: 10px;
  cursor: pointer;
  color: #8A8A8A;
  font-family: var(--poppins-medium);
}

.birth_set{
  display: flex;
}
.textMsg{
    color: #122d5b;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: var(--poppins-medium);
}
.phoneInputSocial {
  input {
    border-radius: 33px !important;
    border-color: rgb(209, 209, 209) !important;
    border-width: 0.5px !important;
    // width: 100% !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    height: 65px !important;
    width: 100% !important;
    // width: auto !important;
    // width: 413px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.phoneInputErrorSocial {
  border: 0.5px solid red !important;
  border-radius: 33px !important;

  text-align: left !important;
  position: relative !important;
  bottom: 0 !important;
  right: 0 !important;
  // top: -5px;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 1px !important;
  overflow: hidden !important;
  min-width: 0% !important;
  overflow: visible !important;
  // width: 413px !important;
  & input {
    border: none !important;
    border-radius: 50% !important;
    height: 65px !important;
    @media (max-height: 695px) {
      height: 50px !important;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      height: 50px !important;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      height: 55px !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.content{
  a{
    font-weight: 400;
    font-family: var(--poppins-regular);
    color: #8a8a8a;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
  }
}