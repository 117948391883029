.contaninerBg {
  background-color: #f3f2f4;
}
.pageTitle {
  color: #122d5b;
  font-size: 38px;
  font-family: "Poppins";
  font-weight: bold;
  padding-inline: 30px;
  max-width: 1440px;
  padding-inline: 30px;
  margin: auto;
  margin-top: 47px;
  margin-bottom: 36px;
}
.pageTitleProvider{
  color: #122d5b;
  font-size: 38px;
  font-family: "Poppins";
  font-weight: bold;
  padding-inline: 30px;
  max-width: 1440px;
  padding-inline: 30px;
  margin: auto;
  margin-top: 47px;
}
.pageTitleSubText {
  margin-top: 11px;
  color: #000000;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  padding-inline: 30px;
  padding-bottom: 38px;
  max-width: 1440px;
  padding-inline: 30px;
  margin: auto;
}
.profileSection {
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding: 25px;
}
.allProfiles {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.eachProfile {
  background-color: #f4f4f4;
  border: 1px solid #c1c1c1;
  color: var(--background-color);
  border-radius: 10px;
  width: 250px;
  // @media (min-width: 768px) and (max-width: 1024px) {
  //   width: 230px;
  // }
  padding: 16px 26px 16px 26px;
  font-size: 16px;
  font-family: var(--poppins-semibold);
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--background-color);
  }
}
.selected {
  border: 1px solid var(--background-color);
  background-color: var(--background-color);
  color: #f4f4f4;
}
.checkIcon {
  position: absolute;
  right: 20px;
  top: 15px;
}
.profileError {
  color: #ff0000;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  padding: 10px;
  display: block;
}
.bloodTestTakenSection,
.uploadSection {
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding: 23px 32px 38px 23px;
}
.bloodTestTakenText {
  font-size: 22px;
  color: #122d5b;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0;
  padding-bottom: 40px;
}
.allSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1440px;
  margin: auto;
}
.allDropDownWrapper {
  display: flex;
  gap: 25px;
}
.datePickerContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 0 0 284px;
}
.dropdownHeading {
  margin: 0;
  color: #122d5b;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
  padding-left: 24px;
}
.labproviderWrapper {
  flex: 0 0 284px;
}
.reasonWrapper {
  flex: 1 1 auto;
}
.uploadTitleText {
  font-size: 22px;
  color: #122d5b;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0;
  margin-bottom: 29px;
}
.uploadSubText {
  font-size: 16px;
  font-family: "Poppins";
  color: #000;
  font-weight: 400;
  padding-block: 16px 28px;
  display: block;
}
.uploadButtonsContainer {
  display: flex;
  align-items: center;
  gap: 38px;
}
.ORtext {
  color: #e68413;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
}
.recentlyUploadedText {
  margin: 0;
  padding-top: 25px;
  padding-bottom: 27px;
  color: #122d5b;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
}
.uploadedImagesContainer {
  display: flex;
  gap: 50px;
}
.uploadedImage {
  position: relative;
}
.closeIcon {
  position: absolute;
  top: -7px;
  left: -7px;
  cursor: pointer;
  height: 17px;
}
.editIcon {
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  font-size: 23px;
}
.BottomNextBtn {
  display: flex;
  justify-content: center;
  padding-block: 28px 40px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .allDropDownWrapper {
    flex-direction: column;
  }
  .datePickerContainer {
    flex: auto;
  }
  .labproviderWrapper {
    flex: auto;
  }
}
.error_msg {
  margin: 0;
  color: red;
  margin-left: 6px;
  margin-top: 8px;
  font-family: var(--poppins-regular);
}
.otherInput {
  border: 1px solid #d1d1d1;
  border-radius: 15px;
  color: #8a8a8a;
  font-size: 16px;
  font-family: var(--poppins-regular);
  margin-top: 20px;
  padding: 18px;

  &:focus-visible {
    outline: none;
  }
}


.dropdown {
  // margin: 0 auto;
  // z-index: 10000;
  // width: 300px;
  position: relative;
  height: 50px;
  // @media (max-height: 695px){
  //   height: 50px;
  // };
  // @media (min-height: 696px) and (max-height: 764px){
  //   height: 50px;
  // };
  // @media (min-height: 765px) and (max-height: 833px) {
  //   height: 55px;
  // };
}
.dropdown,
.dropdown * {
  // z-index: 10;
  
}
.dropdown .dropdown_btn {
  cursor: pointer;
  // background: rgb(177, 146, 146);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  background: white;
  color: #8A8A8A;
  font-family: var(--poppins-medium);
  font-weight: 500;
  border-radius: 15px;
    border: 1px solid rgb(209, 209, 209);
    border-width: 0.5px;
    height: 60px;
    // width: 400px;
    // width: 414px;
// @media (max-width: 1024px){
//    width: 100%;
//   };

}
.dropdown_content {
  position: absolute;
  background-color: #fff;
  /* top: 110%; */
  left: 0;
  width: 100%;
  z-index: 99;
  border-radius: 15px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  overflow: scroll;
}
.dropdown_content .item {
  padding: 10px;
  cursor: pointer;
  color: #8A8A8A;
  font-family: var(--poppins-medium);
}

.birth_set{
  display: flex;
}