.paymentMethodTitle {
  display: flex;
  justify-content: center;
  color: #122d5b !important;
  font-weight: bold !important;
  font-family: var(--font-family);
  margin-bottom: 0;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 25px;
}
.userPlanContainer {
  display: flex;
  & > .AHAlogoImg {
    max-height: 50px;
    border-radius: 10px;
  }
}
.closebtn {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.userPlan {
  padding-inline-start: 20px;
  & .userPlanText {
    color: black;
    font-size: 22px;
    font-family: "Poppins";
    font-weight: 600;
    margin: 0;
    padding-block-end: 5px;
  }
  & .AHASubText {
    color: rgb(113, 113, 113);
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    margin: 0;
  }
}
.durationContainer {
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding-block: 29px;
  & .eachStep {
    display: flex;
    & .blueDotContainer {
      display: flex;
      align-items: center;
      min-width: 40px;
      & .blueDot {
        height: 11px;
        width: 11px;
        background-color: #0188c5;
        border-radius: 50%;
        display: block;
        position: relative;
        &:after {
          content: "";
          height: 37px;
          width: 3px;
          display: block;
          position: absolute;
          top: 7px;
          left: 4px;
          background-color: #0188c5;
        }
      }
      & .lastblueDot {
        height: 11px;
        width: 11px;
        background-color: white;
        border-radius: 50%;
        display: block;
        border: 2px solid #0188c5;
      }
    }
    & .durationTextsWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .leftText {
        margin: 0;
        color: rgb(32, 32, 32);
        font-size: 16px;
        font-family: "Poppins";
        font-weight: 500;
      }
      & .rightText {
        margin: 0;
        color: rgb(32, 32, 32);
        font-size: 16px;
        font-family: "Poppins";
        font-weight: 500;
      }
    }
  }
}
.NotesContainer {
  & .includedTaxText {
    margin: 0;
    color: rgb(95, 95, 95);
    font-size: 11px;
    font-family: "Poppins";
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 1.2;
    & > span {
      display: flex;
      padding-left: 6px;
      cursor: pointer;
    }
  }
  & .notesPoints {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block: 7px;
    & > li {
      color: rgb(95, 95, 95);
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 400;
    }
  }
}
.paymentMethodLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block-start: 20px;
  & > label {
    color: #122d5b;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
  }
  & .paymentButton {
    background-color: #122d5b;
    padding: 16px 0;
    border-radius: 33px;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    margin-block: 20px 30px;
  }
}

@media (min-height: 500px) and (max-height: 665px) {
  .paymentMethodTitle {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .closebtn {
    right: 20px;
    top: 20px;
    & svg {
      height: 13px;
    }
  }
  .userPlanContainer {
    display: flex;
    & > .AHAlogoImg {
      max-height: 45px;
      border-radius: 7px;
    }
  }
  .userPlan {
    padding-inline-start: 16px;
    & .userPlanText {
      font-size: 18px;
    }
    & .AHASubText {
      font-size: 14px;
    }
  }
  .durationContainer {
    gap: 18px;
    padding-block: 20px;
    & .eachStep {
      & .blueDotContainer {
        min-width: 35px;
        & .blueDot {
          height: 9px;
          width: 9px;
          &:after {
            content: "";
            height: 33px;
            left: 3px;
          }
        }
        & .lastblueDot {
          height: 9px;
          width: 9px;
        }
      }
      & .durationTextsWrapper {
        & .leftText {
          font-size: 14px;
        }
        & .rightText {
          font-size: 14px;
        }
      }
    }
  }
  .NotesContainer {
    & .includedTaxText {
      font-size: 10px;
    }
    & .notesPoints {
      gap: 6px;
      margin-block: 10px;
      & > li {
        font-size: 11px;
      }
    }
  }
  .paymentMethodLabel {
    // padding-block-start: 10px;
    padding-block-start: 6px;
    & > label {
      color: #122d5b;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
    }
    & .paymentButton {
      background-color: #122d5b;
      padding-block: 12px;
      border-radius: 33px;
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 500;
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
      margin-block: 20px;
    }
  }
}
