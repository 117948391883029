.codeTitle {
    color: #000;
    font-family: var(--poppins-bold) !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: bold !important;
    line-height: normal;
    text-decoration-line: underline;
}

.subTitle {
    color: #000;
    font-family: var(--poppins-medium);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.table {
    font-family: var(--poppins-medium);
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        // border: 1px solid #dddddd;
        border: none;
        text-align: left;
        padding: 8px;
        color: #000;
        font-family: var(--poppins-medium);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // text-decoration-line: underline;
    }

   

    // tr:nth-child(even) {
    //     background-color: #dddddd;
    // }
}