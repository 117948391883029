.contaninerBg {
    background-color: #f3f2f4;
    padding-bottom: 42px;

    .patientInfo_Title{
        font-size: 22px;
        color: var(--background-color);
        font-family: var(--poppins-medium);
        margin-top: 49px;
        margin-bottom: 26px;
    }
  }
  .BottomNextBtn {
    display: flex;
    justify-content: center;
    padding-block: 0px 40px;
    gap: 10px;
  }
  .back_button{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottomButton{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .title{
    font-size: 22px;
    color: var(--background-color);
    font-family: var(--poppins-medium);
    margin-top: 30px;
    margin-bottom: 26px;
    margin-right: 6%;
    text-transform: uppercase;
    text-align: center;
  }

  .comprehensive_card{
    width: 100%;
    border-radius: 15px !important;
    margin-top: 10px;
    box-shadow: none !important;

    .comp_main{
      padding: 26px 20px 32px 20px;

      .comp_content1{
        font-size: 16px;
        font-family: var(--poppins-regular);
        color: #000000;
        margin-top: 10px;
        margin-bottom: 0;
      }
      .comp_content2{
        font-size: 16px;
        font-family: var(--poppins-regular);
        color: #000000;
        margin-top: 20px;
        margin-bottom: 0;
      }
    }

  }