.signInTitle {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 32px;
  color: var(--background-color);
  word-spacing: 0;
  text-align: center;
  margin-block: 40px 30px;
  line-height: 43px;
  @media (max-height: 695px) {
    margin-block: 27px 15px;
    font-size: 26px;
  }
  @media (min-height: 696px) and (max-height: 764px) {
    margin-block: 31px 21px;
    font-size: 28px;
  }
  @media (min-height: 765px) and (max-height: 833px) {
    margin-block: 35px 24px;
    font-size: 30px;
  }
}
.staySign {
  display: flex;
  justify-content: space-between;

  .forgotText {
    display: flex;
    font-family: var(--font-family);
    font-size: 14px;
  }
  p {
    font-family: var(--font-family);
    font-size: 14px;
  }
  .forgotPass {
    cursor: pointer;
    color: black;
  }
}
.SignButtonBox {
  margin-top: 15px;
  .signInBtn {
    border-radius: 33px;
    background-color: var(--background-color) !important;
    font-family: var(--font-family);
    color: white !important;
    font-weight: bold;
    border: none;
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    padding: 20px 12px;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    @media (max-height: 695px) {
      font-size: 16px;
      padding: 12px 12px;
    }
    @media (min-height: 696px) and (max-height: 764px) {
      padding: 15px 12px;
    }
    @media (min-height: 765px) and (max-height: 833px) {
      padding: 18px 12px;
    }
  }
}
.options {
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: bold;
  margin-top: 0;
}
.facebookBtn {
  background-color: white;
  font-family: var(--font-family) !important;
  border: 1px solid #d1d1d1 !important;
  margin: 0 !important;
  padding-inline: 0 !important;
  @media (max-height: 695px) {
    padding-block: 10px !important;
    padding-inline: 0 !important;
  }
  @media (min-height: 696px) and (max-height: 764px) {
    padding-block: 14px !important;
    padding-inline: 0 !important;
  }
  @media (min-height: 765px) and (max-height: 833px) {
    padding-block: 18px !important;
    padding-inline: 0 !important;
  }
  &:hover {
    background-color: white;
  }
}
.signUpText {
  display: flex;
  justify-content: center;

  p {
    font-size: 16px;
    margin-block: 34px 36px;
    font-family: var(--poppins-regular);
  }

  span {
    font-size: 16px;
    font-family: var( --poppins-bold);
    color: var(--background-color);
    cursor: pointer;
  }
}
.closebtn {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.errorMessage {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-left: 19px;
  font-family: var(--poppins-regular);
}
.inputError {
  border: 0.5px solid red !important;
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0 8px;
  border-radius: 33px;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;

  &:hover {
    border-color: red !important;
  }
}
.passwordShow{
  cursor: pointer;
}