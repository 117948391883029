.accordionWrapper {
  width: 96%;
  padding: 5px 7px;
  margin: 2px auto 10px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 7px;
  .accordion {
    width: 100%;
    height: 48px;
    display: flex;
    // justify-content: space-between;
    // justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    .verticalLine {
      height: 32px;
      width: 3px;
      position: absolute;
      top: 8px;
      left: -7px;
      border-radius: 7px;
      user-select: none;

      @media (min-width: 1921px) and (max-width: 2049px) {
        top: 2px;
        height: 38px;
      }
      @media (min-width: 1708px) and (max-width: 1920px) {
        top: 2px;
      }
      @media (min-width: 1398px) and (max-width: 1707px) {
        top: 3px;
        width: 2px !important;
        height: 27px;
      }
      @media (min-width: 1230px) and (max-width: 1397px) {
        top: 3px;
        height: 42px;
      }
      @media (min-width: 1153px) and (max-width: 1229px) {
        top: 3px;
        height: 42px;
      }
      @media (min-width: 1099px) and (max-width: 1152px) {
        height: 42px;
        top: 3px;
      }
      @media (min-width: 1025px) and (max-width: 1098px) {
        top: 3px;
        height: 43px;
      }
      @media (min-width: 767px) and (max-width: 1024px) {
        top: 3px;
        height: 42px;
      }
    }
    .accordionHeadings {
      color: #122d5b;
      font-family: var(--poppins-bold);
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      padding-left: 0.75rem;
      align-items: center;
      width: 26%;
      width: 26%;
      user-select: none;

      @media (min-width: 1921px) {
      }
      @media (min-width: 1708px) and (max-width: 1920px) {
      }
      @media (min-width: 1398px) and (max-width: 1707px) {
      }
      @media (min-width: 1230px) and (max-width: 1397px) {
      }
      @media (min-width: 1153px) and (max-width: 1229px) {
      }
      @media (min-width: 1099px) and (max-width: 1152px) {
      }
      @media (min-width: 1025px) and (max-width: 1098px) {
      }
      @media (min-width: 767px) and (max-width: 1024px) {
      }
    }
    .reportCounts {
      width: 64%;
      width: 64%;
      display: flex;
      justify-content: space-around;
      justify-content: space-around;
      align-items: center;
      user-select: none;
      // margin-right: auto;
      @media (min-width: 1921px) {
      }
      @media (min-width: 1708px) and (max-width: 1920px) {
        width: 68%;
      }
      @media (min-width: 1398px) and (max-width: 1707px) {
        width: 64%;
      }
      @media (min-width: 1230px) and (max-width: 1397px) {
        width: 68%;
      }
      @media (min-width: 1153px) and (max-width: 1229px) {
        width: 68%;
      }
      @media (min-width: 1099px) and (max-width: 1152px) {
        width: 68%;
      }
      @media (min-width: 1025px) and (max-width: 1098px) {
        width: 68%;
      }
      @media (min-width: 767px) and (max-width: 1024px) {
        width: 68%;
      }
      // margin-right: auto;
      @media (min-width: 1921px) {
      }
      @media (min-width: 1708px) and (max-width: 1920px) {
        width: 68%;
      }
      @media (min-width: 1398px) and (max-width: 1707px) {
        width: 64%;
      }
      @media (min-width: 1230px) and (max-width: 1397px) {
        width: 68%;
      }
      @media (min-width: 1153px) and (max-width: 1229px) {
        width: 68%;
      }
      @media (min-width: 1099px) and (max-width: 1152px) {
        width: 68%;
      }
      @media (min-width: 1025px) and (max-width: 1098px) {
        width: 68%;
      }
      @media (min-width: 767px) and (max-width: 1024px) {
        width: 68%;
      }
      span {
        font-family: var(--poppins-bold);
      }
    }
    .expandedIcon {
      background-color: unset;
      outline: none;
      border: none;
      width: auto;
      position: relative;
      left: 21px;
      svg {
        @media (min-width: 1153px) and (max-width: 1229px) {
          height: 20px;
          width: 20px;
        }
        @media (min-width: 1099px) and (max-width: 1152px) {
          height: 20px;
          width: 20px;
        }
        @media (min-width: 1025px) and (max-width: 1098px) {
          height: 20px;
          width: 20px;
        }
      }
      &.expanded {
        transform: rotate(0deg);
      }
      &.closed {
        transform: rotate(180deg);
      }
    }
  }
  .AccordionDetails {
    overflow: hidden;
    width: 100%;
    .biomarkerGraphWrapper {
      display: flex;
      .blankDiv {
        width: 20%;
        display: flex;
        justify-content: end;
        .units {
          user-select: none;
          font-size: 10px;
          font-family: var(--poppins-medium);
          color: black;
          line-height: 14px;
          margin-right: 7px;
          margin-top: 3px;
        }
      }
      .graphDiv {
        width: 75%;
        position: relative;
        @media (min-width: 1099px) and (max-width: 1152px) {
          width: 73%;
        }
        .graphDates {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 5px;
          div {
            width: 84%;
            width: 84%;
            height: 100%;
            margin-left: 3%;
            margin-left: 3%;
            display: flex;
            justify-content: space-around;
            justify-content: space-around;
            align-items: flex-end;
            // @media (min-width: 1919px) and (max-width: 2048px) {
            //   width: 77%;
            // }
            span {
              color: black;
              font-size: 9px;
              font-family: var(--poppins-regular);
              letter-spacing: 0;
              position: relative;
              top: 5px;

              &.graphDatesSpan {
                min-width: 20px;
              }
            }
            @media (min-width: 1921px) {
              // min-width: 412px !important;
              width: 100%;
              height: 100%;
              margin-left: 10%;
            }
            @media (min-width: 1707px) and (max-width: 1920px) {
              // min-width: 412px !important;
              width: 91%;
              height: 100%;
              margin-left: 7%;
            }
            @media (min-width: 1230px) and (max-width: 1397px) {
              // min-width: 412px !important;
              width: 88%;
              height: 100%;
              margin-left: 10%;
            }
            @media (min-width: 1153px) and (max-width: 1229px) {
              // min-width: 412px !important;
              width: 91%;
              height: 100%;
              margin-left: 8%;
            }
            @media (min-width: 1099px) and (max-width: 1152px) {
              // min-width: 412px !important;
              width: 88%;
              height: 100%;
              margin-left: 10%;
            }
            @media (min-width: 1025px) and (max-width: 1098px) {
              // min-width: 412px !important;
              width: 88%;
              height: 100%;
              margin-left: 10%;
            }
            @media (min-width: 767px) and (max-width: 1024px) {
              width: 88%;
              height: 100%;
              margin-left: 10%;
            }
          }
          @media (min-width: 1921px) {
            height: 90%;
          }
          @media (min-width: 1708px) and (max-width: 1920px) {
            height: 92%;
          }
          @media (min-width: 1398px) and (max-width: 1707px) {
            height: 92%;
          }
          @media (min-width: 1230px) and (max-width: 1397px) {
            height: 92%;
          }
          @media (min-width: 1153px) and (max-width: 1229px) {
            height: 91%;
          }
          @media (min-width: 1099px) and (max-width: 1152px) {
            height: 91%;
          }
          @media (min-width: 1025px) and (max-width: 1098px) {
            height: 90%;
          }
          @media (min-width: 767px) and (max-width: 1024px) {
            height: 94%;
          }
          @media (min-width: 1921px) {
            height: 90%;
          }
          @media (min-width: 1708px) and (max-width: 1920px) {
            height: 92%;
          }
          @media (min-width: 1398px) and (max-width: 1707px) {
            height: 92%;
          }
          @media (min-width: 1230px) and (max-width: 1397px) {
            height: 92%;
          }
          @media (min-width: 1153px) and (max-width: 1229px) {
            height: 100%;
          }
          @media (min-width: 1099px) and (max-width: 1152px) {
            height: 100%;
          }
          @media (min-width: 1025px) and (max-width: 1098px) {
            height: 90%;
          }
          @media (min-width: 767px) and (max-width: 1024px) {
            height: 94%;
          }
        }
        .canvas {
          width: 412px !important;
          height: auto !important;
          @media (min-width: 1536px) and (max-width: 1706px) {
            min-width: 412px !important;
          }
          @media (min-width: 1230px) and (max-width: 1535px) {
            height: auto !important;
          }
          @media (min-width: 767px) and (max-width: 1024px) {
            height: auto !important;
          }
          @media (min-width: 1153px) and (max-width: 1229px) {
            width: 356px !important;
            height: auto !important;
          }
          @media (min-width: 1099px) and (max-width: 1152px) {
            width: 100% !important;
            height: auto !important;
          }
          @media (min-width: 1025px) and (max-width: 1098px) {
            height: 100% !important;
            width: 100% !important;
          }
          @media (min-width: 767px) and (max-width: 1024px) {
            height: 100% !important;
            width: 100% !important;
          }
          canvas {
            @media (min-width: 767px) and (max-width: 1024px) {
              height: 100% !important;
              width: 100% !important;
            }
          }
        }
        @media (min-width: 1919px) {
          width: 80%;
        }
      }
    }
    .organInformation {
      background-color: #f0f8ff;
      border-radius: 7px;
      margin: 8px auto 0 auto;
      padding: 15px;
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        user-select: none;
      }
      p {
        font-size: 13px;
        margin: 0 auto;
        // padding-right: 20px;
        text-align: justify;
        user-select: none;
        font-family: var(--poppins-regular);
        max-width: 562px;
        @media (min-width: 1536px) and (max-width: 1706px) {
          font-size: 10px;
        }
        @media (min-width: 1230px) and (max-width: 1535px) {
          font-size: 10px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          font-size: 10px;
        }
        @media (min-width: 1153px) and (max-width: 1229px) {
          font-size: 9px;
        }
        @media (min-width: 1099px) and (max-width: 1152px) {
          font-size: 9px;
        }
        @media (min-width: 1025px) and (max-width: 1098px) {
          font-size: 8px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      button {
        width: fit-content;
        font-family: var(--poppins-semibold);
        user-select: none;
        border: none;
        background-color: unset;
        cursor: pointer;
        text-transform: capitalize;
        margin: 10px 25px 0 auto;
        user-select: none;
        @media (min-width: 1536px) and (max-width: 1706px) {
          font-size: 10px;
        }
        @media (min-width: 1230px) and (max-width: 1535px) {
          font-size: 10px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          font-size: 10px;
        }
        @media (min-width: 1153px) and (max-width: 1229px) {
          font-size: 9px;
        }
        @media (min-width: 1099px) and (max-width: 1152px) {
          font-size: 9px;
        }
        @media (min-width: 1025px) and (max-width: 1098px) {
          font-size: 8px;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
    &.openAccordion {
      max-height: auto;
    }
    &.closeAccordion {
      max-height: 0px;
    }
  }
}

@media (min-width: 1921px) and (max-width: 2048px) {
  .accordionWrapper {
    .accordion {
      height: 42px;
      .expandedIcon {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
@media (min-width: 1708px) and (max-width: 1920px) {
  .accordionWrapper {
    .accordion {
      height: 35px;

      .accordionHeadings {
        font-size: 13px;
      }
      .reportCounts {
        span {
          font-size: 12px;
          color: black;
        }
      }
      .expandedIcon {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
    .AccordionDetails {
      .biomarkerGraphWrapper {
        .graphDiv {
          canvas {
            height: unset !important;
          }
        }
      }
      .organInformation {
        button {
          font-size: 12px;
          font-family: var(--poppins-semibold);
        }
      }
    }
  }
}
@media (min-width: 1439px) and (max-width: 1707px) {
  .accordionWrapper {
    .accordion {
      height: 32px;

      .accordionHeadings {
        font-size: 13px;
      }

      .expandedIcon {
        left: 49px;
        top: 0px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .AccordionDetails {
      .biomarkerGraphWrapper {
        .graphDiv {
          canvas {
            height: unset !important;
            // width: 100% !important;
          }
        }
      }
      .organInformation {
        padding: 10px 8px;
        button {
          font-size: 10px;
        }
      }
    }
  }
}
@media (min-width: 1397px) and (max-width: 1440px) {
  .accordionWrapper {
    .accordion {
      height: 32px;

      .accordionHeadings {
        font-size: 13px;
      }
      .expandedIcon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .AccordionDetails {
      .biomarkerGraphWrapper {
        .graphDiv {
          .canvas {
            width: 100% !important;
            // height: 192px !important;
            // height: 192px !important;
            canvas {
              height: unset !important;
              width: 100% !important;
            }
          }
          .graphDates {
            div {
              span {
                top: 11px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1229px) and (max-width: 1396px) {
  .accordion {
    .expandedIcon {
      left: 47px;
      top: 0px;
    }
  }
}
@media (min-width: 1153px) and (max-width: 1228px) {
  .accordion {
    .expandedIcon {
      left: 47px;
      top: 0px;
    }
  }
}
@media (min-width: 1099px) and (max-width: 1152px) {
  .accordion {
    .expandedIcon {
      left: 47px;
      top: 0px;
    }
  }
}
@media (min-width: 1025px) and (max-width: 1098px) {
  .accordion {
    .expandedIcon {
      left: 47px;
      top: 0px;
    }
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .accordion {
    .expandedIcon {
      left: 47px;
      top: 0px;
    }
  }
}
@media (min-width: 1241px) and (max-width: 1366px) {
  .accordionWrapper {
    .AccordionDetails {
      .biomarkerGraphWrapper {
        .graphDiv {
          .canvas {
            width: 376px !important;
          }
        }
      }
    }
  }
}
