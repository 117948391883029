.mainContainer {
  max-width: 1440px;
  margin-inline: auto;
}

.contaninerBg {
  background-color: #f3f2f4;
}

.pageTitle {
  margin-top: 47px;
  color: #122d5b;
  font-size: 38px;
  font-family: "Poppins";
  font-weight: bold;
  padding-inline: 30px;
  padding-bottom: 27px;
}

.sectionContainer {
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding-top: 43px;
  padding-inline: 43px;
  padding-bottom: 67px;
}

.allSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sectionHeading {
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #122d5b;
  margin: 0;
}

.sectionSubHeading {
  font-size: 16px;
  line-height: 21px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
  margin: 0;
  margin-bottom: 25px;
}

.review_main {
  background-color: #f4f4f4;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  width: 100%;
  height: 225px;
  flex: 0 0 32%;
  p {
    margin: 0;
  }

  .review_profile {
    background-color: #122d5b;
    border-radius: 10px;
    margin: 9px 8px 17px 8px;
    display: flex;
    align-items: center;

    .review_profileName {
      font-family: var(--poppins-semibold);
      font-size: 16px;
      color: white;
      padding: 19px 90px 19px 26px;
      margin: 0;
    }

    .review_shareIcon {
      margin: 20px 28px 21px auto;
      cursor: pointer;
    }

    .review_closeIcon {
      margin: 23px 15px 24px auto;
      cursor: pointer;
    }
  }
}

.mainSection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.review_profile_section {
  background-color: #122d5b;
  border-radius: 10px;
  margin: 9px 8px 17px 8px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 19px 15px 19px 26px;
  font-family: var(--poppins-semibold);
  font-size: 16px;
  justify-content: space-between;

  .innerSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .icconSet {
      display: flex;
      align-items: center;
      gap: 28px;

      & svg {
        cursor: pointer;
      }
    }
  }
}

.addBtnLink {
  color: #e68413;
  font-family: var(--poppins-semibold);
  font-size: 14px;
  padding-left: 34px;
  border: none;
  background: none;
  cursor: pointer;
}

.review_profileList {
  display: flex;
  padding: 0px 17px 10px 34px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    color: #000000;
    font-size: 16px;
    font-family: var(--poppins-regular);
  }
}

.restore_main {
  margin: 37px 0 35px 0px;
  display: flex;
  gap: 14px;
  flex-wrap: wrap;

  .inner_main {
    background-color: #f4f4f4;
    border: 1px solid #c1c1c1;
    border-radius: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 49.3333%;

    p {
      padding: 30px 0 33px 26px;
      color: var(--background-color);
      font-size: 18px;
      font-family: var(--poppins-semibold);
      margin: 0;
    }

    .restore_button {
      border: none;
      background-color: var(--background-color);
      border-radius: 28px;
      color: white;
      font-size: 18px;
      font-family: var(--poppins-bold);
      padding: 16px 85px 15px 86px;
      margin: 15px 12px 17px 0;
      cursor: pointer;
    }
  }
}

.set_section {
  display: flex;
  align-items: center;
  gap: 18px;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 10px;
  padding-top: 10px;

  p {
    margin: 0;
    color: #000000;
    font-size: 16px;
    font-family: var(--poppins-regular);
  }

  div {
    display: flex;
  }
}

.feedback_main {
  display: grid;
  gap: 12px;
  margin-top: 33px;

  .feedbackSubject {
    border-radius: 15px;
    border: 1px solid #d1d1d1;
    padding: 21px 0 21px 26px;
    font-size: 16px;
    color: #8a8a8a;
    font-family: var(--poppins-regular) !important;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
  .feedbackSubjectError {
    border-radius: 15px;
    border: 1px solid red;
    padding: 21px 0 21px 26px;
    font-size: 16px;
    color: #8a8a8a;
    font-family: var(--poppins-regular) !important;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
  .feedbackMessage {
    border-radius: 15px;
    border: 1px solid #d1d1d1;
    padding: 21px 0 60px 26px;
    font-size: 16px;
    color: #8a8a8a;
    font-family: var(--poppins-regular) !important;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
  .feedbackMessageError {
    border-radius: 15px;
    border: 1px solid red;
    padding: 21px 0 60px 26px;
    font-size: 16px;
    color: #8a8a8a;
    font-family: var(--poppins-regular) !important;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
}
.getAppButton {
  padding: 15px 42px;
  color: white;
  background-color: var(--background-color);
  border: 1px solid #d1d1d1;
  font-size: 1rem;
  font-family: var(--poppins-bold);
  border-radius: 33px;
  cursor: pointer;
  letter-spacing: 1px;
  user-select: none;
}

.subscription_section {
  padding: 66px 0 47px 0;
  display: flex;
  justify-content: space-between;

  .lable_form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #707070;
    border-radius: 15px;
    padding: 28px 36px 28px 23px;
    width: 588px;

    label {
      gap: 27px;
    }

    p {
      color: #ff9900;
      font-size: 17px;
      margin: 0;
    }

    span {
      color: #122d5b;
      // font-size: 17px;
      font-family: var(--poppins-regular);
      padding: 0;
    }

    &.selectedPlans {
      pointer-events: none;
      user-select: none;
      box-shadow: 10px 20px 40px #00000014;
      border: 3px solid #122d5b;
    }
    &.currentlySelectedPlan {
      box-shadow: 10px 20px 40px #00000014;
      border: 2px solid #122d5b;
      font-family: var(--poppins-medium);
    }
  }
}

.user_plan {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.note_info {
  color: #8a8a8a;
  margin: 0;
  text-align: center;
  margin-top: 23px;
  font-size: 16px;
  font-family: var(--poppins-italic);
}

.purchase_btn {
  background-color: #122d5b;
  color: #ffffff;
  font-size: 16px;
  font-family: var(--poppins-bold);
  padding: 17px 83px 16px 84px;
  border-radius: 28px;
  cursor: pointer;
  border: none;
}
.display_acc {
  display: flex;
  align-items: center;
}
.sectionContainer_acc {
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding-top: 43px;
  padding-inline: 43px;
  padding-bottom: 40px;
}
.sectionSubHeading_acc {
  font-size: 16px;
  line-height: 21px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
  margin: 0;
  width: 800px;
}
.acc_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.purchase_disabledBtn {
  background-color: rgba(18, 45, 91, 0.6392156863) !important;
  color: #ffffff;
  font-size: 16px;
  font-family: var(--poppins-bold);
  padding: 17px 83px 16px 84px;
  border-radius: 28px;
  cursor: not-allowed;
  border: none;
}
.btn_link {
  display: flex;
  justify-content: center;
  margin-top: 49px;
  gap: 1rem;
}
.feedback_button_main {
  display: flex;
  justify-content: center;

  .feedback_button {
    margin-top: 29px;
    border-radius: 33px;
    background-color: #122d5b;
    border: 1px solid #d1d1d1;
    color: white;
    font-size: 18px;
    font-family: var(--poppins-bold);
    padding: 20px 86px 20px 87px;
    cursor: pointer;
  }
}
.lastSection {
  display: flex;
  gap: 16px;
  max-width: 1440px;
  padding-bottom: 60px;
  padding-inline: 30px;
  margin-top: 9px;

  .closeAccount {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 37px 43px 36px 43px;
    width: 50%;

    .close_title {
      font-size: 22px;
      color: var(--background-color);
      font-family: var(--poppins-medium);
      margin-top: 0;
      margin-bottom: 31px;
    }

    .close_subTitle {
      font-size: 16px;
      color: #000000;
      font-family: var(--poppins-regular);
      margin-bottom: 33px;
      margin-top: 0;
    }
  }
}
.closeButton_main {
  display: flex;
  justify-content: end;

  button {
    padding: 20px 59px 20px 59px;
    color: white;
    background-color: var(--background-color);
    border: 1px solid #d1d1d1;
    font-size: 18px;
    font-family: var(--poppins-bold);
    border-radius: 33px;
    cursor: pointer;
  }
}
.resetAccount {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 37px 57px 36px 43px;
  width: 50%;

  .reset_title {
    font-size: 22px;
    color: var(--background-color);
    font-family: var(--poppins-medium);
    margin-top: 0;
    margin-bottom: 31px;
  }

  .reset_subTitle {
    font-size: 16px;
    color: #000000;
    font-family: var(--poppins-regular);
    margin-top: 0;
    margin-bottom: 0;
  }
}
.reset_input_main {
  margin-top: 31px;
  margin-bottom: 37px;
}
.resetButton_main {
  display: flex;
  justify-content: end;

  .reset_btn {
    padding: 20px 53px 20px 54px;
    color: white;
    background-color: var(--background-color);
    border: 1px solid #d1d1d1;
    font-size: 18px;
    font-family: var(--poppins-bold);
    border-radius: 33px;
    cursor: pointer;
  }
}
.reset_input {
  border: 1px solid #d1d1d1;
  border-radius: 15px;
  font-size: 16px;
  font-family: var(--poppins-regular);
  color: #8a8a8a;
  padding: 21px 0px 21px 35px;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
}
.font_weight {
  font-family: var(--poppins-bold);
  font-weight: 900;
}
.font_normal {
  font-family: var(--poppins-medium);
}
.confirm_text {
  color: #000000;
  // font-size: 20px;
  font-family: var(--poppins-regular);
}
.modal_footer {
  display: flex;
  justify-content: end;
  gap: 9px;
  align-items: center;

  .confirm_btn {
    background-color: var(--background-color) !important;
    color: #fff !important;
    border-radius: 33px !important;
    padding: 6px 30px !important;
    cursor: pointer;
    border: none !important;
    font-size: 16px !important;
    font-family: var(--poppins-bold);
    height: 40px;
    cursor: pointer;
    &.keepSubscriptionBtn {
      height: 60px;
      padding: 20px !important;
    }
  }
}
.disable_btn {
  margin-top: 29px;
  border-radius: 33px;
  background-color: #122d5ba3 !important;
  border: 1px solid #d1d1d1;
  color: white;
  font-size: 18px;
  font-family: var(--poppins-bold);
  padding: 20px 86px 20px 87px;
  cursor: not-allowed;
}
.share_submitBtn_disable {
  background-color: #122d5ba3;
  color: #fff;
  border-radius: 33px;
  padding: 14px 50px;
  cursor: not-allowed;
  border: none;
  font-size: 16px;
  font-family: var(--poppins-bold);
}
.expiryDate {
  color: red;
  font-size: 14px;
  padding-left: 2px;
  font-family: var(--poppins-medium);
  line-height: 2;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .mainSection {
    // grid-template-columns: auto auto;
    flex-direction: column;
    display: flex;
  }
  .subscription_section {
    flex-direction: column;
    gap: 20px;
  }
  .restore_main {
    // flex-wrap: nowrap;
    flex-wrap: wrap;
  }
  .inner_main {
    flex: 0 48% !important;
  }
  .restore_button {
    padding: 16px 40px 15px 40px !important;
  }
  .review_main {
    margin-bottom: 0;
    height: auto;
    flex: 0 0 47%;
  }
}
.blur_error {
  border: 1px solid red;
  border-radius: 15px;
  font-size: 16px;
  font-family: var(--poppins-regular);
  color: #8a8a8a;
  padding: 21px 0px 21px 35px;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
}

.share_closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.share_title {
  display: flex;
  justify-content: start;
  color: #122d5b !important;
  font-weight: bold !important;
  font-family: var(--poppins-bold);
  margin-bottom: 0;
  font-size: 32px;
  margin-top: 20px;
}
.share_desc {
  font-size: 15px;
  font-family: var(--poppins-regular);
  color: #8a8a8a;
}
.share_submitBtn {
  background-color: var(--background-color) !important;
  color: #fff !important;
  border-radius: 33px !important;
  padding: 14px 50px !important;
  cursor: pointer;
  border: none !important;
  font-size: 16px !important;
  font-family: var(--poppins-bold);
}
.errorMsg {
  color: red;
  font-size: 14px;
  font-family: var(--poppins-medium);
  margin-left: 10px;
}
.checkbox_content {
  display: flex;
  gap: 13px;

  p {
    font-family: var(--poppins-regular);
    font-size: 14px;
    color: #8a8a8a;
  }
}
.feedback_main_box {
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding-top: 43px;
  padding-inline: 43px;
  padding-bottom: 67px;
  padding-bottom: 23px;
  margin-top: 12px;

  .feedback_heading {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 12px;
    font-family: var(--poppins-medium);
    color: #122d5b;
    margin: 0;
  }

  .feedback_subheading {
    font-size: 16px;
    line-height: 21px;
    font-family: var(--poppins-regular);
    color: #000;
    margin: 0;
    margin-bottom: 2px;
  }
  .feedback_subheading2 {
    font-size: 16px;
    line-height: 21px;
    font-family: var(--poppins-regular);
    color: #000;
    margin: 0;
    margin-bottom: 41px;
  }
}
.confirmation_header {
  color: #000000;
  font-size: 30px;
  text-align: center;
  font-family: var(--poppins-medium);
  margin: 0;
}
.confirmation_subHeader {
  color: #000000;
  font-size: 20px;
  text-align: center;
  font-family: var(--poppins-regular);
  margin: 10px;
}
.resetButton_boxStyle {
  margin-bottom: 30px;
  padding-bottom: 45px;
  background: white;
  border-radius: 15px;
  max-width: 1440px;
  margin-inline: 30px;
  padding-top: 43px;
  padding-inline: 43px;

  .resetButton_mainTitle {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 12px;
    font-family: "Poppins";
    font-weight: 500;
    color: #122d5b;
    margin: 0;
  }

  .resetButton_description {
    font-size: 16px;
    line-height: 21px;
    font-family: "Poppins";
    font-weight: 400;
    color: #000;
    margin: 0;
  }
}
.closeAccount_boxStyle {
  padding-bottom: 45px;
}
.reset_box {
  display: flex;
  justify-content: space-between;
}
