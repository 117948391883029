.overview_main {
    background-color: white !important;
    border-radius: 15px !important;
    padding: 10px !important;
    box-shadow: none !important;
}

.vertical_line {
    width: 5px;
    height: 42px;
    // background-color: #FF0000;
    border-radius: 4px;
    margin: 0 12px 0 14px;
}

.align_data {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
}

.organMain {
    background-color: #fff;
    border-radius: 15px;
}

.grid_title {
    margin-right: 10px;
    max-width: 31.333333% !important;
}

.grid_set {
    justify-content: center;
    align-items: center;
    // margin-left: -22px !important;

}

.body_image {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.organ_data {
    margin: 0;
    color: black;
    font-family: var(--poppins-regular);
    font-size: 16px;
    letter-spacing: 0.235px;
}

.organImpactstyle {
    margin: 10;
    color: black;
    font-family: var(--poppins-regular);
    font-size: 16px;
    letter-spacing: 0.235px;
}

.organ_li {
    color: black;
    font-family: var(--poppins-regular);
    font-size: 16px;
    letter-spacing: 0.235px;
}

.set_display_organ {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    display: grid;
}

.accordion_summary_organ {
    border: 1px solid #D1D1D1 !important;
    height: 76px;
    padding: 0px 10px;
    max-width: 100%;
    /* margin-top: 28px; */
    border-radius: 15px !important;
    margin-top: 28px;
}

.organMainRole {
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;

    .MuiAccordion-root.Mui-expanded {
        margin-top: 0px !important;
    }
}

.grid_title_role {
    max-width: 25.333333% !important;
    margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .set_accordian {
        display: flex;
        justify-content: center;
    }

    .set_display_organ {
        display: block;
    }
}