.summary_main{
    background-color: white !important;
    border-radius: 15px !important;
    padding: 25px 21px 25px 25px !important;
    box-shadow: none  !important;

    .summary_details{
        font-size: 18px;
        font-family: var(--poppins-medium);
        color: #000000;
        margin-top: 0;
    }

    .summary_newDetails{
        margin-top: 0;
    }
    p{
        font-size: 16px;
        font-family: var(--poppins-regular);
        color: #000000;
        margin-top: 30px;
        margin-bottom: 0;
    }
}

.report_summary_title{
    font-size: 22px;
    color: var(--background-color);
    font-family: var(--poppins-medium);
    margin-top: 35px;
}
.summaryPara{
    background-color: #fff;
    border-radius: 15px;
}
.summaryParaRole{
    background-color: #fff;
    border-radius: 15px;
    display: grid;
    align-items: center;
    grid-template-columns: 67% 32%;
    column-gap: 5px;
}