.modelWrapper {
  width: 80%;
  margin: auto;

  .share_title {
    text-align: center !important;
    font-family: var(--poppins-semibold);
  }
  .share_info {
    text-align: center;
    font-family: var(--poppins-semibold);
    margin: 0 auto;
    padding: 0;
    width: 80%;
  }
}

.review_profile_section {
  background-color: #122d5b;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 21px;
  font-family: var(--poppins-semibold);
  font-size: 16px;
  justify-content: space-between;

  .innerSection {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .innerSectionX {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icconSet {
        display: flex;
        align-items: center;
        gap: 28px;

        & svg {
          cursor: pointer;
        }
      }
    }
  }
}
