.accordionWrapper {
  width: 100%;
  word-wrap: break-word;
  .accordion {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    .accordionHeadings {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      user-select: none;
      font-family: var(--poppins-medium);
      margin-left: 10px;
      p {
        font-family: var(--poppins-medium);
      }
    }
    .expandedIcon {
      background-color: unset;
      outline: none;
      border: none;
      margin-left: auto;

      &.expanded {
        transform: rotate(0deg);
      }
      &.closed {
        transform: rotate(180deg);
      }
    }
  }
  .AccordionDetailsContainer {
    border-radius: 15px;
    width: 100%;
    .AccordionDetails {
      overflow: hidden;
      &.openAccordion {
        max-height: auto;
      }
      &.closeAccordion {
        max-height: 0;
      }
    }
  }
}

@media (max-width: 1919px) {
  .accordionWrapper {
    .accordion {
      height: 40px;
    }
    .AccordionDetailsContainer {
      margin-top: 5px;
    }
  }
}

@media (max-width: 1707px) {
  .accordionWrapper {
    .accordion {
      height: 32px;
      .accordionHeadings {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
